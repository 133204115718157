import React, {useEffect, useState} from 'react';
import {Row, Table} from "react-bootstrap";
import {useLeague, useMatchups, useNflState, useRosters, useUsers} from "../hooks/sleeper";
import {AVATAR_DEFAULT_URL_PREFIX, getRosterIdToRosterMap, getUserIdToUserMap} from "../lib/utils/sleeper";
import TeamAvatar from "./TeamAvatar";

interface TeamRecord {
    displayName: string
    avatar: string
    wins: number
    losses: number
    ties: number
    status: string
}

const SHEEP = "🐑";
const WOLF = "🐺";
const THINK = "🤔";

function AllPlayRecord({hidden, displayName}: { hidden: boolean, displayName: string }) {
    const matchups = useMatchups();
    const nflState = useNflState();
    const league = useLeague();
    const users = useUsers();
    const rosters = useRosters();
    const [teamRecords, setTeamRecords] = useState<TeamRecord[]>([]);
    const [leagueStatus, setLeagueStatus] = useState("");

    useEffect(() => {
        if (rosters.length === 0 || users.length === 0 || matchups.length === 0 || nflState === undefined || league === undefined) {
            return;
        }

        // Determine Current Week
        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;

        // Setup maps
        let userIdUserMap = getUserIdToUserMap(users);
        let rosterIdToRostersMap = getRosterIdToRosterMap(rosters);
        let rosterIdToTeamRecordMap = new Map<number, TeamRecord>();
        let currentUser = users.find(u => u.display_name === displayName);
        if (currentUser === undefined) {
            throw new Error(`Unable to find roster by owner id ${displayName}`);
        }
        let userId = currentUser.user_id;
        let currentRoster = rosters.find(r => r.owner_id === userId);
        if (currentRoster === undefined) {
            throw new Error(`Unable to find roster by owner id ${userId}`);
        }
        let currentRosterId = currentRoster.roster_id;

        matchups.filter(m => m.week < current_week).forEach(m => {
            let myMatchupThisWeek = m.matchups.filter(m => m.roster_id === currentRosterId)[0];

            m.matchups.forEach((m) => {
                let myScore = myMatchupThisWeek.points;
                let otherRosterId = m.roster_id;
                let otherScore = m.points;
                let teamRecord = rosterIdToTeamRecordMap.get(otherRosterId);
                if (teamRecord === undefined) {
                    let otherRoster = rosterIdToRostersMap.get(otherRosterId);
                    if (otherRoster === undefined) {
                        throw new Error(`Unable to find roster by id ${otherRosterId}`);
                    }

                    let currentUser = userIdUserMap.get(otherRoster.owner_id);
                    if (currentUser === undefined) {
                        throw new Error(`Unable to find user for id: ${otherRoster.owner_id}`);
                    }

                    let avatar = (currentUser.metadata.avatar !== undefined) ? currentUser.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + currentUser.avatar;


                    teamRecord = {
                        displayName: currentUser.display_name,
                        avatar: avatar,
                        wins: 0,
                        losses: 0,
                        ties: 0,
                        status: ""
                    };
                    rosterIdToTeamRecordMap.set(otherRosterId, teamRecord);
                }

                if (myScore > otherScore) {
                    teamRecord.wins++;
                } else if (myScore < otherScore) {
                    teamRecord.losses++;
                } else {
                    teamRecord.ties++;
                }
            });
        });

        let nextTeamRecords = Array.from(rosterIdToTeamRecordMap.entries())
            .map((k) => k[1])
            .filter(t => t.displayName !== displayName)
            .sort((a, b) => b.wins - a.wins);

        // set wolf/sheep
        let wolfCount = 0;
        let sheepCount = 0;
        let thinkCount = 0;
        nextTeamRecords.forEach(nextTeamRecord => {
           if(nextTeamRecord.wins > nextTeamRecord.losses) {
               nextTeamRecord.status = WOLF;
               wolfCount++;
           } else if (nextTeamRecord.losses > nextTeamRecord.wins) {
               nextTeamRecord.status = SHEEP;
               sheepCount++;
           } else {
               nextTeamRecord.status = THINK;
               thinkCount++;
           }
        });

        let leagueStatus = THINK;
        if(wolfCount > (sheepCount + thinkCount)) {
            leagueStatus = WOLF;
        }else if (sheepCount > (wolfCount + thinkCount)) {
            leagueStatus = SHEEP;
        }

        setLeagueStatus(leagueStatus);
        setTeamRecords(nextTeamRecords);

    }, [displayName, users, rosters, matchups, nflState, league]);

    return (
        <div className="">
            <Row className="text-center">
                <h5>All Play Record</h5>
                <h6 hidden={leagueStatus === ""}>League Status: {leagueStatus}</h6>
            </Row>
            <Table size="sm" className="nowrap reallySmallTable text-center table-bordered table-striped"
                   hidden={hidden}>
                <thead>
                <tr className="text-center">
                    <th>Opponent</th>
                    <th>Wins</th>
                    <th>Losses</th>
                    <th>{SHEEP} / {WOLF}</th>
                </tr>
                </thead>
                <tbody>
                {
                    teamRecords.map(teamRecord =>
                        <tr className="text-center" key={displayName + '-' + teamRecord.displayName}>
                            <th className="text-start"><TeamAvatar displayName={teamRecord.displayName}
                                                                   avatar={teamRecord.avatar} avatarLeft={true}
                                                                   highlight={false}/></th>
                            <td>{teamRecord.wins}</td>
                            <td>{teamRecord.losses}</td>
                            <td>{teamRecord.status}</td>
                        </tr>
                    )
                }
                </tbody>
            </Table>
        </div>
    );
}

export default AllPlayRecord;