import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import MatchupContainer, {MatchupContainerProps} from "./MatchupContainer";
import {HeadToHeadMatchup, useHeadToHeadMatchups, useLeague, useNflState, useRosters, useUsers} from "../hooks/sleeper";
import {
    AVATAR_DEFAULT_URL_PREFIX,
    getRosterIdToDisplayNameMap,
    getRosterIdToRosterMap,
    getUserIdToUserMap,
    Roster,
    User
} from "../lib/utils/sleeper";

type MetricSelector = (matchup: HeadToHeadMatchup) => MetricSelection;
type MatchupReducer = (a: MatchupContainerProps, b: MatchupContainerProps) => MatchupContainerProps;

const HIGHLIGHTS: Highlight[] = [
    {
        title: "Highest Team Score", metricSelector: (m => {
            const metric = Math.max(m.homeTeam.points, m.awayTeam.points);
            const rosterId = m.homeTeam.points === metric ? m.homeTeam.roster_id : m.awayTeam.roster_id;
            return {metric: metric, rosterId: rosterId};
        }), reducer: toLargest()
    },
    {
        title: "Lowest Team Score", metricSelector: (m => {
            const metric = Math.min(m.homeTeam.points, m.awayTeam.points);
            const rosterId = m.homeTeam.points === metric ? m.homeTeam.roster_id : m.awayTeam.roster_id;
            return {metric: metric, rosterId: rosterId};
        }), reducer: toLowest()
    },
    {
        title: "Highest Shootout", metricSelector: (m => {
            const metric = m.homeTeam.points + m.awayTeam.points;
            return {metric: metric, rosterId: undefined};
        }), reducer: toLargest()
    },
    {
        title: "Lowest Shootout", metricSelector: (m => {
            const metric = m.homeTeam.points + m.awayTeam.points;
            return {metric: metric, rosterId: undefined};
        }), reducer: toLowest()
    },
    {
        title: "Biggest Blowout", metricSelector: (m => {
            const metric = Math.abs(m.homeTeam.points - m.awayTeam.points);
            const rosterId = m.homeTeam.points > m.awayTeam.points ? m.homeTeam.roster_id : m.awayTeam.roster_id;
            return {metric: metric, rosterId: rosterId};
        }), reducer: toLargest()
    },
    {
        title: "Closest Game", metricSelector: (m => {
            const metric = Math.abs(m.homeTeam.points - m.awayTeam.points);
            const rosterId = m.homeTeam.points > m.awayTeam.points ? m.homeTeam.roster_id : m.awayTeam.roster_id;
            return {metric: metric, rosterId: rosterId};
        }), reducer: toLowest()
    }
];

interface Highlight {
    title: string,
    metricSelector: MetricSelector,
    reducer: MatchupReducer
}

interface MetricSelection {
    metric: number,
    rosterId: number | undefined
}

function toMatchupContainer(highlight: Highlight, rosterIdToDisplayNameMap: Map<number, string>, userIdUserMap: Map<string, User>, rosterIdToRostersMap: Map<number, Roster>) {
    return (m: HeadToHeadMatchup) => {
        const homeRosterId = m.homeTeam.roster_id;
        const awayRosterId = m.awayTeam.roster_id;

        const homeName = rosterIdToDisplayNameMap.get(m.homeTeam.roster_id);
        const awayName = rosterIdToDisplayNameMap.get(m.awayTeam.roster_id);
        if (homeName === undefined || awayName === undefined) {
            throw new Error(`Unable to find display names for roster ids`);
        }

        const homeRoster = rosterIdToRostersMap.get(homeRosterId);
        const awayRoster = rosterIdToRostersMap.get(awayRosterId);
        if (homeRoster === undefined || awayRoster === undefined) {
            throw new Error(`Unable to find rosters for roster ids`);
        }

        const homeUser = userIdUserMap.get(homeRoster.owner_id);
        const awayUser = userIdUserMap.get(awayRoster.owner_id);
        if (homeUser === undefined || awayUser === undefined) {
            throw new Error(`Unable to find users from owner ids`);
        }

        let homeAvatar = (homeUser.metadata.avatar !== undefined) ? homeUser.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + homeUser.avatar;
        let awayAvatar = (awayUser.metadata.avatar !== undefined) ? awayUser.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + awayUser.avatar;

        const r = highlight.metricSelector(m);
        let selectedDisplayName = "";
        if (r.rosterId !== undefined) {
            let found = rosterIdToDisplayNameMap.get(r.rosterId);
            if (found !== undefined) {
                selectedDisplayName = found;
            }
        }

        let matchupContainer: MatchupContainerProps = {
            title: highlight.title,
            home: homeName,
            homeAvatar: homeAvatar,
            away: awayName,
            awayAvatar: awayAvatar,
            week: m.week,
            metric: r.metric,
            selectedDisplayName: selectedDisplayName
        };
        return matchupContainer;
    };
}

function toLargest() {
    return (a: MatchupContainerProps, b: MatchupContainerProps) => {
        return a.metric - b.metric > 0 ? a : b;
    };
}

function toLowest() {
    return (a: MatchupContainerProps, b: MatchupContainerProps) => {
        return b.metric - a.metric > 0 ? a : b;
    };
}

function MatchupAwards() {
    const matchups = useHeadToHeadMatchups();
    const nflState = useNflState();
    const league = useLeague();
    const rosters = useRosters();
    const users = useUsers();
    const [currentWeek, setCurrentWeek] = useState(0);

    const [matchupContainerProps, setMatchupContainerProps] = useState<MatchupContainerProps[]>([]);
    useEffect(() => {
        if (matchups.length === 0 || rosters.length === 0 || users.length === 0 || league === undefined || nflState === undefined) {
            return;
        }

        // Determine Current Week
        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;

        const userIdMap = getUserIdToUserMap(users);
        const rosterIdToDisplayNameMap = getRosterIdToDisplayNameMap(rosters, userIdMap);
        const userIdUserMap = getUserIdToUserMap(users);
        const rosterIdToRostersMap = getRosterIdToRosterMap(rosters);

        let matchupContainerPropsUpdate: MatchupContainerProps[] = [];

        // Apply each highlight
        if (current_week > 1) {
            HIGHLIGHTS.forEach(highlight => {
                const matchupContainerProp = matchups.filter(m => m.week < current_week).map(toMatchupContainer(highlight, rosterIdToDisplayNameMap, userIdUserMap, rosterIdToRostersMap)).reduce(highlight.reducer);
                matchupContainerPropsUpdate.push(matchupContainerProp);
            });
        }

        setCurrentWeek(current_week);
        setMatchupContainerProps(matchupContainerPropsUpdate);
    }, [matchups, rosters, users, league, nflState]);

    return (
        <Row hidden={currentWeek <= 1}>
            <Col sm={12} className="text-center">
                <h2>Matchup Awards</h2>
            </Col>
            {matchupContainerProps.map((props, idx) => <Col key={idx} xs={12}
                                                            xl={6}><MatchupContainer {...props} /></Col>)}
        </Row>
    );
}

export default MatchupAwards;