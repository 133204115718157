import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {useTitle} from "../hooks/common";
import {useParams} from 'react-router-dom';

import {useTeamIdentifiers} from "../hooks/TeamIdentifiers";
import TeamPositionTable from "../components/TeamPositionTable";
import TeamAvatar from "../components/TeamAvatar";
import {useRosters, useUsers} from "../hooks/sleeper";
import {AVATAR_DEFAULT_URL_PREFIX, getRosterIdToRosterMap, getUserIdToUserMap} from "../lib/utils/sleeper";
import AllPlayRecord from "../components/AllPlayRecord";

export default function Team() {
    useTitle("Team | FMFFL");

    const { teamId } = useParams();
    const teams = useTeamIdentifiers();
    const users = useUsers();
    const rosters = useRosters();

    const [selectedDisplayName, setSelectedDisplayName] = useState("");
    const [selectedAvatar, setSelectedAvatar] = useState("");

    useEffect(() => {
        if(teamId === undefined || teams.length === 0 || users.length === 0 || rosters.length === 0) {
            return;
        }

        try {
            let foundTeam = teams.find(team => team.displayName.toLowerCase() ===  teamId.toLowerCase());
            if (foundTeam !== undefined) {
                console.log("Found team", foundTeam);

                const userIdUserMap = getUserIdToUserMap(users);
                const rosterIdToRostersMap = getRosterIdToRosterMap(rosters);

                let currentRoster = rosterIdToRostersMap.get(foundTeam.rosterId);
                if (currentRoster === undefined) {
                    return;
                }
                let currentUser = userIdUserMap.get(currentRoster.owner_id);
                if (currentUser === undefined) {
                    return;
                }

                let avatar = (currentUser.metadata.avatar !== undefined) ? currentUser.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + currentUser.avatar;

                setSelectedDisplayName(foundTeam.displayName);
                setSelectedAvatar(avatar);
            } else {
                console.log("No team found for roster id", teamId);
            }
        } catch (e) {
            console.log("Failed to load url key.. skipping", e);
        }
    }, [teamId, teams, users, rosters]);

    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={4}></Col>
                <Col sm={4} className="text-center">
                    <h2>
                        <TeamAvatar displayName={selectedDisplayName} avatar={selectedAvatar} avatarLeft={true} highlight={false}/>
                    </h2>
                </Col>
                <Col sm={4}></Col>
                <hr/>
            </Row>
            <TeamPositionTable hidden={selectedDisplayName === ""} displayName={selectedDisplayName}/>
            <AllPlayRecord hidden={selectedDisplayName === ""} displayName={selectedDisplayName}/>
        </Container>
    );
}