import React, {useEffect, useState} from "react";
import {Col, Image, Row, Table} from "react-bootstrap";
import {AVATAR_DEFAULT_URL_PREFIX, User} from "../lib/utils/sleeper";
import {useRosters, useUsers} from "../hooks/sleeper";

interface Division {
    name: number,
    teams: Team[]
}

interface Team {
    avatar: string,
    displayName: string,
    wins: number,
    loss: number,
    ties: number,
    pointsFor: number,
    pointsAgainst: number
}

function sumTies(division: Division) {
    return division.teams.map(t => t.ties).reduce((x, y) => x + y);
}

function DivisionColumn({division}: { division: Division }) {
    return (
        <Col xl={4}>
            <h3 className="text-center">Division {division.name}</h3>
            <Table striped bordered hover className="nowrap">
                <thead>
                <tr>
                    <th>Team</th>
                    <th>W-L{sumTies(division) > 0 ? '-T' : ''}</th>
                    <th>PF</th>
                    <th>PA</th>
                </tr>
                </thead>
                <tbody>
                {division.teams.map(team => <TeamTableRow key={team.displayName} team={team}/>)}
                </tbody>
            </Table>
        </Col>
    );
}

function TeamTableRow({team}: { team: Team }) {
    return (
        <tr>
            <td><Image width={25} className={"player-image rounded"} src={team.avatar}/> {team.displayName}</td>
            <td>{team.wins}-{team.loss}{team.ties > 0 ? `-${team.ties}` : ''}</td>
            <td>{team.pointsFor}</td>
            <td>{team.pointsAgainst}</td>
        </tr>
    );
}
export default function LeagueStandings() {
    const rosters = useRosters();
    const users = useUsers();

    const [divisions, setDivisions] = useState<Division[]>([]);

    useEffect(() => {
        if(rosters.length === 0 || users.length === 0) {
            return;
        }

        let userIdUserMap = new Map<string, User>();
        users.forEach(user => userIdUserMap.set(user.user_id, user));
        console.log("userIdMap", userIdUserMap);

        let divisions = new Map<number, Division>();
        rosters.forEach(roster => {
            let user =  userIdUserMap.get(roster.owner_id);
            let teamName = (user === undefined) ? "unknown" : user.display_name;
            let avatar = "";
            if (user !== undefined) {
                avatar = (user.metadata.avatar !== undefined) ? avatar = user.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + user.avatar;
            }

            let pointsFor = roster.settings.fpts === 0 ? 0 : roster.settings.fpts + roster.settings.fpts_decimal * 0.01;
            let pointsAgainst = roster.settings.fpts === 0 ? 0 : roster.settings.fpts_against + roster.settings.fpts_against_decimal * 0.01;

            let team: Team = {
                avatar: avatar,
                displayName: teamName,
                wins: roster.settings.wins,
                loss: roster.settings.losses,
                ties: roster.settings.ties,
                pointsFor: pointsFor,
                pointsAgainst: pointsAgainst
            };

            let divisionName = roster.settings.division;
            let division = divisions.get(divisionName);
            if (division === undefined) {
                division = {
                    name: divisionName,
                    teams: []
                };
                divisions.set(divisionName, division);
            }

            division.teams.push(team);
        });

        divisions.forEach(division => {
            let teams = [...division.teams];
            teams.sort((t1,t2) => {
                // sort by wins
                if (t1.wins > t2.wins) {
                    return -1;
                }
                if (t1.wins < t2.wins) {
                    return 1;
                }

                // sort by points
                if (t1.pointsFor > t2.pointsFor) {
                    return -1;
                }
                if (t1.pointsFor < t2.pointsFor) {
                    return 1;
                }

                // tie
                return 0;
            });
            console.log("Division ", division.name);
            console.log(division.teams);
            console.log(teams);
            division.teams = teams;
        });

        setDivisions(Array.from(divisions.values()));
    }, [users, rosters]);

    return (
        <Row>{divisions.map(division => <DivisionColumn key={division.name} division={division}/>)}</Row>
    );
}