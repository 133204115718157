import {Col, Container, Image, Pagination, Row, Table, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    AVATAR_DEFAULT_URL_PREFIX,
    getMatchupIdToMatchupsMap,
    getRosterIdToDisplayNameMap,
    getUserIdToUserMap,
    User
} from "../lib/utils/sleeper";
import {useLeague, useMatchups, useNflState, useRosters, useUsers} from "../hooks/sleeper";
import {useTitle} from "../hooks/common";
import {useSearchParams} from 'react-router-dom';


const checkbox = '✅';
const thinkface = '🤔';
const red_x = '❌';
const party = '🎉';
const poop = '💩';
const medal_1st = '🥇';
const trophy = '🏆';
const NUM_TEAMS = 12;
const BIT_POWER = 4;
const WORD_POWER = 36;
const MAKE_PLAYOFFS = 8;


type MatchupSelectionFunction = (matchupSelection: MatchupSelection) => void;

interface Team {
    division: number,
    rosterId: number,
    avatar: string,
    displayName: string,
    wins: number,
    losses: number,
    ties: number,
    pointsFor: number,
    pointsAgainst: number,
    playoffStatus: string,
    divisionWinner: boolean,
    firstPlace: boolean
}

interface TeamTiebreaker {
    displayName: string,
    pointsFor: number
    wins: number,
    winsSelections: HeadToHeadSelection[];
    losses: number,
    lossesSelections: HeadToHeadSelection[];
    ties: number,
    tiesSelections: HeadToHeadSelection[];
    winPercentage: number
}

interface HeadToHeadMatchup {
    matchupId: string,
    week: number,
    homeTeamName: string,
    homeTeamAvatar: string,
    awayTeamName: string,
    awayTeamAvatar: string,
    result: MatchupSelectionType
}


interface HeadToHeadSelection {
    matchupId: string,
    homeTeamName: string,
    awayTeamName: string,
    result: MatchupSelectionType
}

interface MatchupSelection {
    week: number,
    matchupId: number
    result: MatchupSelectionType
}

enum MatchupSelectionType {
    HOME_WINS, AWAY_WINS, TIE, UNSELECTED
}

function TieBreakerLog({title, tieBreakerLog}: {title:string, tieBreakerLog: string[] }) {
    return (
        <Row className="mt-2">
            <h3>{tieBreakerLog.length > 0 ? title : ""}</h3>
            {tieBreakerLog.map((line, idx) => <p key={idx}>{line}</p>)}
        </Row>
    );
}

function PlayoffsMatchupWeek({
                                 week,
                                 selectedWeek,
                                 matchups,
                                 onMatchupSelection
                             }: { week: number, selectedWeek: number, matchups: HeadToHeadMatchup[], onMatchupSelection: MatchupSelectionFunction }) {
    return (
        <Row hidden={selectedWeek !== week}>
            <Col sm={12}>
                <Row>
                    {matchups.filter(m => m.week === week).map(matchup => <PlayoffHeadToHeadMatchup
                        key={matchup.matchupId}
                        matchup={matchup}
                        onMatchupSelection={onMatchupSelection}/>)}
                </Row>
            </Col>
        </Row>
    );
}

function PlayoffHeadToHeadMatchup({
                                      matchup,
                                      onMatchupSelection
                                  }: { matchup: HeadToHeadMatchup, onMatchupSelection: MatchupSelectionFunction }) {
    const [value, setValue] = useState<MatchupSelectionType>(matchup.result);

    function handleChange(matchupSelectionType: MatchupSelectionType) {
        let matchupId = parseInt(matchup.matchupId.split("-")[1]);
        if (matchupId === undefined) {
            throw new Error("Unable to split matchupId");
        }

        let matchupSelection: MatchupSelection = {
            week: matchup.week,
            matchupId: matchupId,
            result: matchupSelectionType
        };

        onMatchupSelection(matchupSelection);
        setValue(matchupSelectionType);
    }

    return (
        <>
            <ToggleButtonGroup type="radio" name={"options-" + matchup.week + "-" + matchup.matchupId}
                               onChange={handleChange} value={value}>
                <ToggleButton id={"options-" + matchup.week + "-" + matchup.matchupId + "-1"}
                              variant="outline-secondary" value={MatchupSelectionType.HOME_WINS}
                              className="playoffTeamButton">
                    <div className="playoffTeamLeft">
                        <Image width={20} className={"player-image rounded"}
                               src={matchup.homeTeamAvatar}/> {matchup.homeTeamName}
                    </div>
                </ToggleButton>
                <ToggleButton id={"options-" + matchup.week + "-" + matchup.matchupId + "-2"}
                              variant="outline-secondary" value={MatchupSelectionType.TIE} className="playoffTieButton">
                    T
                </ToggleButton>
                <ToggleButton id={"options-" + matchup.week + "-" + matchup.matchupId + "-3"}
                              variant="outline-secondary" value={MatchupSelectionType.AWAY_WINS}
                              className="playoffTeamButton">
                    <div className="playoffTeamRight">
                        {matchup.awayTeamName} <Image width={20} className={"player-image rounded"}
                                                      src={matchup.awayTeamAvatar}/>
                    </div>
                </ToggleButton>
            </ToggleButtonGroup>
        </>
    );
}

function DivisionColumn({division, teams}: { division: number, teams: Team[] }) {
    return (
        <Col xl={4}>
            <h3 className="text-center">Division {division}</h3>
            <Table striped bordered hover className="nowrap">
                <thead>
                <tr>
                    <th>Team</th>
                    <th>W-L-T</th>
                    <th>PF</th>
                    <th>Result</th>
                </tr>
                </thead>
                <tbody>
                {teams.filter(t => t.division === division).map(team => <TeamTableRow key={team.displayName}
                                                                                      team={team}/>)}
                </tbody>
            </Table>
        </Col>
    );
}

function TeamTableRow({team}: { team: Team }) {
    return (
        <tr>
            <td><Image width={25} className={"player-image rounded"} src={team.avatar}/> {team.displayName}</td>
            <td>{team.wins}-{team.losses}-{team.ties}</td>
            <td>{team.pointsFor}</td>
            <td>{team.playoffStatus}</td>
        </tr>
    );
}

function byRecord(): (t1: Team, t2: Team) => (1 | -1 | 0) {
    return (t1: Team, t2: Team) => {
        // Give division Winner advantage
        if(t1.divisionWinner) {
            return -1;
        }

        // sort by wins
        if (t1.wins > t2.wins) {
            return -1;
        }
        if (t1.wins < t2.wins) {
            return 1;
        }

        // sort by points
        if (t1.pointsFor > t2.pointsFor) {
            return -1;
        }
        if (t1.pointsFor < t2.pointsFor) {
            return 1;
        }

        // tie
        return 0;
    };
}

function byId(): (t1: HeadToHeadMatchup, t2: HeadToHeadMatchup) => (1 | -1 | 0) {
    return (t1: HeadToHeadMatchup, t2: HeadToHeadMatchup) => {
        if (t1.matchupId > t2.matchupId) {
            return 1;
        }
        if (t1.matchupId < t2.matchupId) {
            return -1;
        }
        return 0;
    };
}

function toMap(matchupSelections: MatchupSelection[]) {
    const matchupSelectionById = new Map<string, MatchupSelection>();
    matchupSelections.forEach(m => {
        const matchupId = getMatchupId(m);
        matchupSelectionById.set(matchupId, m);
    });
    return matchupSelectionById;
}

function getMatchupId(matchupSelection: MatchupSelection) {
    return `${matchupSelection.week}-${matchupSelection.matchupId}`;
}

function byMatchupId() {
    return (a: HeadToHeadSelection, b: HeadToHeadSelection) => {
        const [aWeekStr, aMatchupIdStr] = a.matchupId.split("-");
        const [bWeekStr, bMatchupIdStr] = b.matchupId.split("-");

        const [weekA, idA] = [parseInt(aWeekStr), parseInt(aMatchupIdStr)];
        const [weekB, idB] = [parseInt(bWeekStr), parseInt(bMatchupIdStr)];

        if (weekA === weekB) {
            return idA - idB;
        }

        return weekA - weekB;
    };
}

function getRosterIdToTeamMap(teams: Team[]) {
    const rosterIdToTeam = new Map<number, Team>();
    teams.forEach(team => {
        rosterIdToTeam.set(team.rosterId, team);
    });
    return rosterIdToTeam;
}

function getMatchupIdToMatchupSelectionMap(matchupSelections: MatchupSelection[]) {
    const matchupIdToMatchupSelectionMap = new Map<string, MatchupSelection>();
    matchupSelections.forEach(matchupSelection => {
        const matchupId = getMatchupId(matchupSelection);
        matchupIdToMatchupSelectionMap.set(matchupId, matchupSelection);
    });
    return matchupIdToMatchupSelectionMap;
}

function breakTie(maybePlayoffs: Team[], headToHeadSelections: HeadToHeadSelection[], tieBreakerLines: string[]) : Team {
    let maybeTeamNames = maybePlayoffs.map(t => t.displayName);
    let teamTiebreakers: TeamTiebreaker[] = [];

    maybePlayoffs.map(team => {
        let teamTieBreaker: TeamTiebreaker = {
            displayName: team.displayName,
            pointsFor: team.pointsFor,
            wins: 0,
            winsSelections: [],
            losses: 0,
            lossesSelections: [],
            ties: 0,
            tiesSelections: [],
            winPercentage: 0,
        };
        return teamTieBreaker;
    }).forEach(teamTiebreaker => {
        // Iterate through all matchups in this maybe group
        headToHeadSelections.filter(s => {
            let maybeTeamNamesFiltered = maybeTeamNames.filter(displayName => displayName !== teamTiebreaker.displayName);
            let matchupTeams = [s.homeTeamName, s.awayTeamName];
            let includesMyTeam = matchupTeams.includes(teamTiebreaker.displayName);
            let includesMaybeTeamOpponents = maybeTeamNamesFiltered.includes(s.homeTeamName) || maybeTeamNamesFiltered.includes(s.awayTeamName);
            return includesMyTeam && includesMaybeTeamOpponents;
        }).forEach(matchup => {
            // console.log(`[${teamTiebreaker.displayName}] Matchup [id=${matchup.matchupId},h=${matchup.homeTeamName},a=${matchup.awayTeamName},r=${matchup.result}]`);
            let isHomeTeam = teamTiebreaker.displayName === matchup.homeTeamName;
            switch (matchup.result) {
                case MatchupSelectionType.HOME_WINS:
                    isHomeTeam ? teamTiebreaker.wins += 1 : teamTiebreaker.losses += 1;
                    isHomeTeam ? teamTiebreaker.winsSelections.push(matchup) : teamTiebreaker.lossesSelections.push(matchup);
                    break;
                case MatchupSelectionType.AWAY_WINS:
                    isHomeTeam ? teamTiebreaker.losses += 1 : teamTiebreaker.wins += 1;
                    isHomeTeam ? teamTiebreaker.lossesSelections.push(matchup) : teamTiebreaker.winsSelections.push(matchup);
                    break;
                case MatchupSelectionType.TIE:
                    teamTiebreaker.ties += 1;
                    teamTiebreaker.tiesSelections.push(matchup);
                    break;
            }
        });

        // console.log(`${tieBreakerLabel} ${teamTiebreaker.displayName} is ${teamTiebreaker.wins}-${teamTiebreaker.losses}-${teamTiebreaker.ties} against ${maybeTeamNames}`);
        teamTiebreakers.push(teamTiebreaker);
    });

    // Compute win percentages
    teamTiebreakers.forEach(teamTiebreaker => {
        teamTiebreaker.winPercentage = teamTiebreaker.wins / (teamTiebreaker.wins + teamTiebreaker.losses + teamTiebreaker.ties) * 100.0;
    });

    // Sort by win percentages
    teamTiebreakers.sort((a, b) => b.winPercentage - a.winPercentage);

    teamTiebreakers.forEach(teamTiebreaker => {
        tieBreakerLines.push(`== ${teamTiebreaker.winPercentage.toFixed(2)}% [${teamTiebreaker.wins}-${teamTiebreaker.losses}-${teamTiebreaker.ties}] ${teamTiebreaker.displayName} (${teamTiebreaker.pointsFor})==`);

        teamTiebreaker.winsSelections.forEach(w => tieBreakerLines.push(`- Week ${w.matchupId.split("-")[0]} [W] ${teamTiebreaker.displayName === w.homeTeamName ? w.awayTeamName : w.homeTeamName}`));
        teamTiebreaker.lossesSelections.forEach(w => tieBreakerLines.push(`- Week ${w.matchupId.split("-")[0]} [L] ${teamTiebreaker.displayName === w.homeTeamName ? w.awayTeamName : w.homeTeamName}`));
        if (teamTiebreaker.ties > 0) {
            teamTiebreaker.tiesSelections.forEach(w => tieBreakerLines.push(`- Week ${w.matchupId.split("-")[0]} [T] ${teamTiebreaker.displayName === w.homeTeamName ? w.awayTeamName : w.homeTeamName}`));
        }
    });

    // Assume 2 spots, 3 way tie
    // Group wins of remaining teams
    let winPercentageToTeams = new Map<number, TeamTiebreaker[]>();
    teamTiebreakers.forEach(team => {
        let group = winPercentageToTeams.get(team.winPercentage);
        if (group === undefined) {
            group = [];
            winPercentageToTeams.set(team.winPercentage, group);
        }
        group.push(team);
    });

    // Work with first group
    let teams: TeamTiebreaker[] = winPercentageToTeams.values().next().value;
    if (teams.length === 1) {
        let tieBreakerTeam = teams[0];
        // console.log(`${tieBreakerLabel} ${tieBreakerTeam.displayName} breaks tie as sole leader in head to head [${maybeTeamNames}]`);
        tieBreakerLines.push(`${party} ${tieBreakerTeam.displayName} breaks tie with best record ${party}`);
        let madePlayoff = maybePlayoffs.find(maybeTeam => maybeTeam.displayName === tieBreakerTeam.displayName);
        if (madePlayoff === undefined) {
            throw new Error("Unable to find team!");
        }
        return madePlayoff;
    } else {
        // Need to decide who to dump
        // console.log(`${tieBreakerLabel} Can't decide who to dump yet.. breaking from loop`, teams);
        const teamsPointsFor = teams.sort((a, b) => b.pointsFor - a.pointsFor);

        let mostPointsForTieBreaker: TeamTiebreaker[] = [];
        let maxPointsFor = 0;
        teamsPointsFor.forEach(teamPointsFor => {
            if (teamPointsFor.pointsFor > maxPointsFor) {
                maxPointsFor = teamPointsFor.pointsFor;
                mostPointsForTieBreaker.push(teamPointsFor);
            } else if (teamPointsFor.pointsFor === maxPointsFor) {
                mostPointsForTieBreaker.push(teamPointsFor);
            }
        });

        if (mostPointsForTieBreaker.length === 1) {
            let tieBreakerTeam = mostPointsForTieBreaker[0];
            tieBreakerLines.push(`${party} ${tieBreakerTeam.displayName} breaks tie with most points ${party}`);
            let selectedTeam = maybePlayoffs.find(t=> t.displayName === tieBreakerTeam.displayName);
            if(selectedTeam === undefined) {
                throw new Error(`Unable to find team: ${tieBreakerTeam.displayName}`);
            }
            return selectedTeam;
        } else {
            let tieBreakerTeam = mostPointsForTieBreaker[0];
            tieBreakerLines.push(`Can't decide who to dump, falling back to coin flip..`);

            let selectedTeam = maybePlayoffs.find(t=> t.displayName === tieBreakerTeam.displayName);
            if(selectedTeam === undefined) {
                throw new Error(`Unable to find team: ${tieBreakerTeam.displayName}`);
            }
            return selectedTeam;
        }
    }
}

export default function Playoffs() {
    useTitle("Playoffs | FMFFL");


    // Load Sleeper State
    const league = useLeague();
    const nflState = useNflState();
    const rosters = useRosters();
    const users = useUsers();
    const matchups = useMatchups();

    const [matchupSelections, setMatchupSelections] = useState<MatchupSelection[]>([]);

    function onMatchupSelection(matchupSelection: MatchupSelection) {
        console.log(`Received update for matchup ${matchupSelection.week}-${matchupSelection.matchupId} with result ${matchupSelection.result}`);

        const matchupSelectionById = toMap([...matchupSelections]);

        const matchupId = getMatchupId(matchupSelection);
        console.log(`${matchupId} before -> `, matchupSelectionById.get(matchupId));
        matchupSelectionById.set(matchupId, matchupSelection);
        console.log(`${matchupId} after -> `, matchupSelectionById.get(matchupId));

        const updatedSelections = Array.from(matchupSelectionById.values());
        setMatchupSelections(updatedSelections);
    }

    const [urlSearchParams, setUrlSearchParams] = useSearchParams();
    const [loadedKey, setLoadedKey] = useState(false);
    useEffect(() => {
        if (league === undefined || nflState === undefined || urlSearchParams === undefined || loadedKey) {
            return;
        }
        // @ts-ignore
        const urlKey = urlSearchParams.get("key");

        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;
        try {
            if (urlKey !== undefined && urlKey !== null) {
                console.log(`Loading url key: ${urlKey}`);
                const currentWeek = Math.min(current_week, playoff_week);
                const picks = urlKey.split("-");
                if (picks.length < currentWeek) {
                    console.log("There are no new picks");
                    return;
                }

                console.log("Working with picks: ", picks);
                let matchupSelections: MatchupSelection[] = [];
                for (let week = currentWeek - 1; week < picks.length; week++) {
                    const ternaryValueStr = picks[week];
                    const ternaryValue = parseInt(ternaryValueStr, WORD_POWER).toString(BIT_POWER).padStart(NUM_TEAMS / 2, '0');
                    if (parseInt(ternaryValue) > 333333) {
                        throw new Error(`Invalid url key ${urlKey}`);
                    }

                    console.log(`Applying outcomes: ${ternaryValueStr} -> ${ternaryValue}`);

                    ternaryValue.split("").forEach((outcome, idx) => {
                        const result = parseInt(outcome);
                        console.log(`Applying outcome [${ternaryValue}] values: ${idx} ${outcome} ${result}`);
                        const matchupSelection: MatchupSelection = {
                            week: week + 1,
                            matchupId: idx + 1,
                            result: result
                        };
                        matchupSelections.push(matchupSelection);
                    });
                }
                setMatchupSelections(matchupSelections);
            } else {
                console.log("No prev key in URL...");
            }
        } catch (e) {
            console.log("Failed to load url key.. skipping", e);
        }

        setLoadedKey(true);
    }, [league, nflState, loadedKey,urlSearchParams]);


    const [headToHeadSelections, setHeadToHeadSelections] = useState<HeadToHeadSelection[]>([]);
    useEffect(() => {
        if (league === undefined || matchups.length === 0 || rosters.length === 0 || users.length === 0 || nflState === undefined) {
            return;
        }

        // Build maps from sleeper info
        const userIdUserMap = getUserIdToUserMap(users);
        const rosterIdToDisplayNameMap = getRosterIdToDisplayNameMap(rosters, userIdUserMap);
        const matchupIdToMatchups = getMatchupIdToMatchupsMap(matchups);
        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;
        const currentWeek = Math.min(current_week, playoff_week);

        // Build all Outcomes
        const headToHeadSelectionsUpdateMap = new Map<string, HeadToHeadSelection>();
        Array.from(matchupIdToMatchups.keys()).forEach(matchupId => {
            let matchups = matchupIdToMatchups.get(matchupId);
            if (matchups === undefined) {
                throw new Error("Matchups not found!");
            }
            let homeMatchup = matchups[0];
            let awayMatchup = matchups[1];

            let homeTeamName = rosterIdToDisplayNameMap.get(homeMatchup.roster_id);
            let awayTeamName = rosterIdToDisplayNameMap.get(awayMatchup.roster_id);
            if (homeTeamName === undefined || awayTeamName === undefined) {
                throw new Error("Missing display name for roster id");
            }

            const week = parseInt(matchupId.split("-")[0]);

            let outcome = MatchupSelectionType.UNSELECTED;
            if (currentWeek > week) {
                if (homeMatchup.points > awayMatchup.points) {
                    outcome = MatchupSelectionType.HOME_WINS;
                } else if (awayMatchup.points > homeMatchup.points) {
                    outcome = MatchupSelectionType.AWAY_WINS;
                } else {
                    outcome = MatchupSelectionType.TIE;
                }
            }

            const headToHeadSelection = {
                matchupId: matchupId,
                homeTeamName: homeTeamName,
                awayTeamName: awayTeamName,
                result: outcome
            };

            headToHeadSelectionsUpdateMap.set(matchupId, headToHeadSelection);
        });

        // Apply selections
        matchupSelections.forEach(selection => {
            const matchupId = getMatchupId(selection);
            const headToHeadSelection = headToHeadSelectionsUpdateMap.get(matchupId);
            if (headToHeadSelection === undefined) {
                throw new Error(`Unable to find matchupId: ${matchupId}`);
            }
            headToHeadSelection.result = selection.result;
        });

        const headToHeadSelectionsUpdate = Array.from(headToHeadSelectionsUpdateMap.values()).sort(byMatchupId());
        // console.log("Saving headToHeadSelections", headToHeadSelectionsUpdate);

        setHeadToHeadSelections(headToHeadSelectionsUpdate);
    }, [league, rosters, users, matchupSelections, matchups, nflState]);

    const [teams, setTeams] = useState<Team[]>([]);
    const [tieBreakerLog, setTieBreakerLog] = useState<string[]>([]);
    const [divisionTieBreakerLog, setDivisionTieBreakerLog] = useState<string[]>([]);
    const [firstPlaceTieBreakerLog, setFirstPlaceTieBreakerLog] = useState<string[]>([]);
    useEffect(() => {
        console.log("Updating teams based deps update");

        function selectPlayoffTeams(sortedTeams: Team[], headToHeadSelections: HeadToHeadSelection[]) {
            let divisionWinnerTeams: Team[] = [];
            let playoffTeams: Team[] = [];

            // Select first place division winners
            let divisions = new Set();
            sortedTeams.forEach(t => divisions.add(t.division));

            // OLD DUMB ALGO
            // divisions.forEach(division => {
            //     let team = sortedTeams.find(t => t.division === division);
            //     if (team === undefined) {
            //         throw new Error(`No team found for division: ${division}`);
            //     }
            //     playoffTeams.push(team);
            // });

            // NEW ALGO FOR DIVISION FIRST
            let divisionTieBreakerLines: string[] = [];
            divisions.forEach(division => {
                let divisionLeaders: Team[] = [];
                sortedTeams.filter(t => t.division === division).forEach(divisionTeam => {
                    if(divisionLeaders.length === 0) {
                        divisionLeaders.push(divisionTeam);
                    } else if (divisionLeaders[0].wins === divisionTeam.wins) {
                        divisionLeaders.push(divisionTeam);
                    }
                });

                let divisionWinner: Team = divisionLeaders[0];
                if(divisionLeaders.length > 1) {
                    divisionTieBreakerLines.push(`!!! Breaking Tie for Winning Division ${division} !!!`);
                    divisionWinner = breakTie(divisionLeaders, headToHeadSelections, divisionTieBreakerLines);
                }

                playoffTeams.push(divisionWinner);
                divisionWinnerTeams.push(divisionWinner);
            });
            setDivisionTieBreakerLog(divisionTieBreakerLines);


            // Find remaining teams
            let remainingTeams = sortedTeams.filter(t => !playoffTeams.includes(t));
            console.log("Remaining teams: ", remainingTeams);

            // Group wins of remaining teams
            let remainingWinsToTeams = new Map<number, Team[]>();
            remainingTeams.forEach(team => {
                let group = remainingWinsToTeams.get(team.wins);
                if (group === undefined) {
                    group = [];
                    remainingWinsToTeams.set(team.wins, group);
                }
                group.push(team);
            });
            console.log("Remaining Win Teams Map", remainingWinsToTeams);

            // Add groups to madePlayoffs
            let maybePlayoffs: Team[] = [];
            let missedPlayoffs: Team[] = [];
            remainingWinsToTeams.forEach(group => {
                if (playoffTeams.length === MAKE_PLAYOFFS || maybePlayoffs.length > 0) {
                    missedPlayoffs.push(...group);
                } else if (group.length + playoffTeams.length > MAKE_PLAYOFFS) {
                    maybePlayoffs.push(...group);
                } else {
                    playoffTeams.push(...group);
                }
            });

            // let missedTeams = sortedTeams.filter(t=> !playoffTeams.includes(t) && !buffer.includes(t));
            console.log("PlayoffTeams: ", playoffTeams);
            console.log("MaybeTeams: ", maybePlayoffs);
            console.log("MissedTeams: ", missedPlayoffs);

            // Determine Tie Breakers
            maybePlayoffs.forEach(t => t.playoffStatus = thinkface);

            if (playoffTeams.length !== MAKE_PLAYOFFS) {
                console.log("#### BREAKING TIES ####");
            }

            let tieBreakerLines = [];
            let tieBreakerCount = 0;
            while (playoffTeams.length !== MAKE_PLAYOFFS) {
                tieBreakerCount++;
                let tieBreakerLabel = `Tie Breaker ${tieBreakerCount}`;
                tieBreakerLines.push(`***** ${tieBreakerLabel} *****`);

                // Get names of teams to break tie with
                let maybeTeamNames = maybePlayoffs.filter(t => {
                    let playoffNames = playoffTeams.map(pt => pt.displayName);
                    let missedNames = missedPlayoffs.map(mt => mt.displayName);
                    return !playoffNames.includes(t.displayName) && !missedNames.includes(t.displayName);
                }).map(t => t.displayName);
                tieBreakerLines.push(`Breaking tie between: ${maybeTeamNames}`);


                let teamTiebreakers: TeamTiebreaker[] = [];
                maybePlayoffs.filter(mp => maybeTeamNames.includes(mp.displayName)).map(team => {
                    let teamTieBreaker: TeamTiebreaker = {
                        displayName: team.displayName,
                        pointsFor: team.pointsFor,
                        wins: 0,
                        winsSelections: [],
                        losses: 0,
                        lossesSelections: [],
                        ties: 0,
                        tiesSelections: [],
                        winPercentage: 0,
                    };
                    return teamTieBreaker;
                }).forEach(teamTiebreaker => {

                    // Iterate through all matchups in this maybe group
                    headToHeadSelections.filter(s => {
                        let maybeTeamNamesFiltered = maybeTeamNames.filter(displayName => displayName !== teamTiebreaker.displayName);
                        let matchupTeams = [s.homeTeamName, s.awayTeamName];
                        let includesMyTeam = matchupTeams.includes(teamTiebreaker.displayName);
                        let includesMaybeTeamOpponents = maybeTeamNamesFiltered.includes(s.homeTeamName) || maybeTeamNamesFiltered.includes(s.awayTeamName);
                        return includesMyTeam && includesMaybeTeamOpponents;
                    }).forEach(matchup => {
                        // console.log(`[${teamTiebreaker.displayName}] Matchup [id=${matchup.matchupId},h=${matchup.homeTeamName},a=${matchup.awayTeamName},r=${matchup.result}]`);
                        let isHomeTeam = teamTiebreaker.displayName === matchup.homeTeamName;
                        switch (matchup.result) {
                            case MatchupSelectionType.HOME_WINS:
                                isHomeTeam ? teamTiebreaker.wins += 1 : teamTiebreaker.losses += 1;
                                isHomeTeam ? teamTiebreaker.winsSelections.push(matchup) : teamTiebreaker.lossesSelections.push(matchup);
                                break;
                            case MatchupSelectionType.AWAY_WINS:
                                isHomeTeam ? teamTiebreaker.losses += 1 : teamTiebreaker.wins += 1;
                                isHomeTeam ? teamTiebreaker.lossesSelections.push(matchup) : teamTiebreaker.winsSelections.push(matchup);
                                break;
                            case MatchupSelectionType.TIE:
                                teamTiebreaker.ties += 1;
                                teamTiebreaker.tiesSelections.push(matchup);
                                break;
                        }
                    });

                    // console.log(`${tieBreakerLabel} ${teamTiebreaker.displayName} is ${teamTiebreaker.wins}-${teamTiebreaker.losses}-${teamTiebreaker.ties} against ${maybeTeamNames}`);
                    teamTiebreakers.push(teamTiebreaker);
                });

                // Compute win percentages
                teamTiebreakers.forEach(teamTiebreaker => {
                    teamTiebreaker.winPercentage = teamTiebreaker.wins / (teamTiebreaker.wins + teamTiebreaker.losses + teamTiebreaker.ties) * 100.0;
                });

                // Sort by win percentages
                teamTiebreakers.sort((a, b) => b.winPercentage - a.winPercentage);

                teamTiebreakers.forEach(teamTiebreaker => {
                    tieBreakerLines.push(`== ${teamTiebreaker.winPercentage.toFixed(2)}% [${teamTiebreaker.wins}-${teamTiebreaker.losses}-${teamTiebreaker.ties}] ${teamTiebreaker.displayName} (${teamTiebreaker.pointsFor})==`);

                    teamTiebreaker.winsSelections.forEach(w => tieBreakerLines.push(`- Week ${w.matchupId.split("-")[0]} [W] ${teamTiebreaker.displayName === w.homeTeamName ? w.awayTeamName : w.homeTeamName}`));
                    teamTiebreaker.lossesSelections.forEach(w => tieBreakerLines.push(`- Week ${w.matchupId.split("-")[0]} [L] ${teamTiebreaker.displayName === w.homeTeamName ? w.awayTeamName : w.homeTeamName}`));
                    if (teamTiebreaker.ties > 0) {
                        teamTiebreaker.tiesSelections.forEach(w => tieBreakerLines.push(`- Week ${w.matchupId.split("-")[0]} [T] ${teamTiebreaker.displayName === w.homeTeamName ? w.awayTeamName : w.homeTeamName}`));
                    }
                });

                // Assume 2 spots, 3 way tie
                // Group wins of remaining teams
                let winPercentageToTeams = new Map<number, TeamTiebreaker[]>();
                teamTiebreakers.forEach(team => {
                    let group = winPercentageToTeams.get(team.winPercentage);
                    if (group === undefined) {
                        group = [];
                        winPercentageToTeams.set(team.winPercentage, group);
                    }
                    group.push(team);
                });

                // Work with first group
                let teams: TeamTiebreaker[] = winPercentageToTeams.values().next().value;
                if (teams.length === 1) {
                    let tieBreakerTeam = teams[0];
                    // console.log(`${tieBreakerLabel} ${tieBreakerTeam.displayName} breaks tie as sole leader in head to head [${maybeTeamNames}]`);
                    tieBreakerLines.push(`${party} ${tieBreakerTeam.displayName} breaks tie with best record ${party}`);
                    let madePlayoff = maybePlayoffs.find(maybeTeam => maybeTeam.displayName === tieBreakerTeam.displayName);
                    if (madePlayoff === undefined) {
                        throw new Error("Unable to find team!");
                    }
                    playoffTeams.push(madePlayoff);
                } else if (teams.length + playoffTeams.length <= MAKE_PLAYOFFS) {
                    const displayNames = teams.map(t=>t.displayName);
                    tieBreakerLines.push(`${party} ${displayNames} as a group break the tie with best record ${party}`);
                    teams.forEach(tieBreakerTeam => {
                        let madePlayoff = maybePlayoffs.find(maybeTeam => maybeTeam.displayName === tieBreakerTeam.displayName);
                        if (madePlayoff === undefined) {
                            throw new Error("Unable to find team!");
                        }
                        playoffTeams.push(madePlayoff);
                    });
                } else {
                    // Need to decide who to dump
                    // console.log(`${tieBreakerLabel} Can't decide who to dump yet.. breaking from loop`, teams);
                    const teamsPointsFor = teams.sort((a,b) => b.pointsFor - a.pointsFor);

                    let mostPointsForTieBreaker:TeamTiebreaker[] = [];
                    let maxPointsFor = 0;
                    teamsPointsFor.forEach(teamPointsFor => {
                        if(teamPointsFor.pointsFor > maxPointsFor) {
                            maxPointsFor = teamPointsFor.pointsFor;
                            mostPointsForTieBreaker.push(teamPointsFor);
                        } else if (teamPointsFor.pointsFor === maxPointsFor) {
                            mostPointsForTieBreaker.push(teamPointsFor);
                        }
                    });

                    if(mostPointsForTieBreaker.length + playoffTeams.length <= MAKE_PLAYOFFS) {
                        // Add all to playoffs
                        mostPointsForTieBreaker.forEach(tieBreakerTeam => {
                            let madePlayoff = maybePlayoffs.find(maybeTeam => maybeTeam.displayName === tieBreakerTeam.displayName);
                            if (madePlayoff === undefined) {
                                throw new Error("Unable to find team!");
                            }
                            playoffTeams.push(madePlayoff);
                        });

                        // Append to log
                        const displayNames = mostPointsForTieBreaker.map(t=>t.displayName);
                        if(displayNames.length > 1) {
                            tieBreakerLines.push(`${party} ${displayNames} as a group break the tie with POINTS FOR ${party}`);
                        }else {
                            tieBreakerLines.push(`${party} ${displayNames[0]} breaks the tie with most points for (so far) ${party}`);
                        }
                    } else {
                        tieBreakerLines.push(`Can't decide who to dump, falling back to coin flip..`);
                        break;
                    }
                }
            }

            if (playoffTeams.length === 8) {
                maybePlayoffs.filter(mp => {
                    let playoffNames = playoffTeams.map(pt => pt.displayName);
                    let missedNames = missedPlayoffs.map(mt => mt.displayName);
                    return !playoffNames.includes(mp.displayName) && !missedNames.includes(mp.displayName);
                }).forEach(t => {
                    t.playoffStatus = red_x;
                    // console.log(`${t.displayName} lost all ${tieBreakerCount} tie breakers`);
                    tieBreakerLines.push(`${poop} ${t.displayName} lost all ${tieBreakerCount} tie breakers ${poop}`);
                });
            }

            // BEGIN: First Place determination
            // Group wins of all teams
            let maxWins = 0;
            let winsToTeams = new Map<number, Team[]>();
            sortedTeams.forEach(team => {
                if(team.wins > maxWins) {
                    maxWins = team.wins;
                }

                let group = winsToTeams.get(team.wins);
                if (group === undefined) {
                    group = [];
                    winsToTeams.set(team.wins, group);
                }
                group.push(team);
            });
            console.log("ALL Win Teams Map", winsToTeams);
            let firstPlaceLines: string[] = [];

            let firstPlaceWinnerGroup = winsToTeams.get(maxWins);
            if(firstPlaceWinnerGroup === undefined) {
                throw new Error(`Unable to find division winner group with ${maxWins} wins`);
            }

            if(firstPlaceWinnerGroup.length === 1) {
                firstPlaceWinnerGroup[0].firstPlace = true;
            } else {
                breakTie(firstPlaceWinnerGroup, headToHeadSelections, firstPlaceLines).firstPlace = true;
            }

            // Set colors
            playoffTeams.forEach(t => t.playoffStatus = checkbox);
            divisionWinnerTeams.forEach(t => {
                t.playoffStatus = medal_1st;
                t.divisionWinner = true;

                if(t.firstPlace) {
                    t.playoffStatus = trophy;
                }
            });
            missedPlayoffs.forEach(t => t.playoffStatus = red_x);
            setFirstPlaceTieBreakerLog(firstPlaceLines);
            setTieBreakerLog(tieBreakerLines);
        }

        async function initTeams() {
            if (league === undefined || rosters === undefined || users === undefined || nflState === undefined) {
                return;
            }

            // Build maps from sleeper info
            let userIdUserMap = new Map<string, User>();
            users.forEach(user => userIdUserMap.set(user.user_id, user));

            // Build Teams
            let teamNameToTeam = new Map<string, Team>();
            rosters.forEach(roster => {
                let user = userIdUserMap.get(roster.owner_id);
                let teamName = (user === undefined) ? "unknown" : user.display_name;
                let avatar = "";
                if (user !== undefined) {
                    avatar = (user.metadata.avatar !== undefined) ? avatar = user.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + user.avatar;
                }

                let pointsFor = roster.settings.fpts === 0 ? 0 : roster.settings.fpts + roster.settings.fpts_decimal * 0.01;
                let pointsAgainst = pointsFor === 0 ? 0 : roster.settings.fpts_against + roster.settings.fpts_against_decimal * 0.01;
                let team: Team = {
                    avatar: avatar,
                    rosterId: roster.roster_id,
                    division: roster.settings.division,
                    displayName: teamName,
                    wins: 0,
                    losses: 0,
                    ties: 0,
                    pointsFor: pointsFor,
                    pointsAgainst: pointsAgainst,
                    playoffStatus: "...",
                    divisionWinner: false,
                    firstPlace: false
                };

                teamNameToTeam.set(team.displayName, team);
            });

            // Compute Records
            headToHeadSelections.forEach(selection => {
                let matchup = selection;
                let homeTeam = teamNameToTeam.get(matchup.homeTeamName);
                let awayTeam = teamNameToTeam.get(matchup.awayTeamName);

                if (homeTeam === undefined) {
                    throw Error(`Home team not found: ${matchup.homeTeamName}`);
                }

                if (awayTeam === undefined) {
                    throw Error(`Away team not found: ${matchup.awayTeamName}`);
                }

                switch (matchup.result) {
                    case MatchupSelectionType.HOME_WINS:
                        homeTeam.wins += 1;
                        awayTeam.losses += 1;
                        break;
                    case MatchupSelectionType.AWAY_WINS:
                        awayTeam.wins += 1;
                        homeTeam.losses += 1;
                        break;
                    case MatchupSelectionType.TIE:
                        homeTeam.ties += 1;
                        awayTeam.ties += 1;
                        break;
                    case MatchupSelectionType.UNSELECTED:
                        break;
                }
            });

            let sortedTeams = Array.from(teamNameToTeam.values()).sort(byRecord());

            // Select Playoff Teams
            const selectionCount = matchupSelections.filter(s => s.result !== MatchupSelectionType.UNSELECTED).length;

            let isCurrentSeason = nflState.season === league.season;
            let playoff_week = league.settings.playoff_week_start;
            let current_week = isCurrentSeason ? nflState.week : playoff_week;

            const currentWeek = Math.min(current_week, playoff_week);
            const totalSelectionsRequired = (playoff_week - currentWeek) * NUM_TEAMS/2;
            if (selectionCount === totalSelectionsRequired) {
                selectPlayoffTeams(sortedTeams, headToHeadSelections);
            } else {
                console.log(`Can't select playoff teams, missing ${totalSelectionsRequired - selectionCount} selections`);
                setTieBreakerLog([]);
            }

            // One last sort (for division winners and champ)
            sortedTeams.sort(byRecord());

            setTeams(sortedTeams);
        }


        initTeams().catch(console.error);
    }, [league, rosters, users, headToHeadSelections, matchupSelections, nflState]);

    const [headToHeadMatchups, setHeadToHeadMatchups] = useState<HeadToHeadMatchup[]>([]);
    useEffect(() => {
        async function loadMatchups() {
            if (league === undefined || teams.length === 0 || nflState === undefined || matchups.length === 0) {
                return;
            }

            // Build Roster Lookup
            const rosterIdToTeam = getRosterIdToTeamMap(teams);

            let isCurrentSeason = nflState.season === league.season;
            let playoff_week = league.settings.playoff_week_start;
            let current_week = isCurrentSeason ? nflState.week : playoff_week;

            const currentWeek = Math.min(current_week, playoff_week);
            const matchupIdToMatchupSelectionMap = getMatchupIdToMatchupSelectionMap(matchupSelections);

            let headToHeadMatchups: HeadToHeadMatchup[] = [];

            for (let week = currentWeek; week < playoff_week; week++) {
                let matchupsThisWeek = matchups[week - 1].matchups;

                let matchupIdToTeams = new Map<string, Team[]>();
                matchupsThisWeek.forEach(matchup => {
                    let team = rosterIdToTeam.get(matchup.roster_id);
                    if (team === undefined) {
                        throw new Error('Missing roster id: ' + matchup.roster_id);
                    }

                    let teams = matchupIdToTeams.get(matchup.matchup_id);
                    if (teams === undefined) {
                        teams = [];
                        matchupIdToTeams.set(matchup.matchup_id, teams);
                    }
                    teams.push(team);
                });

                matchupIdToTeams.forEach((teams, matchupId) => {
                    const matchupIdKey = `${week}-${matchupId}`;
                    const matchupSelection = matchupIdToMatchupSelectionMap.get(matchupIdKey);
                    // console.log(`H2H ${matchupIdKey}`, matchupSelection);
                    headToHeadMatchups.push({
                        week: week,
                        matchupId: matchupIdKey,
                        homeTeamName: teams[0].displayName,
                        homeTeamAvatar: teams[0].avatar,
                        awayTeamName: teams[1].displayName,
                        awayTeamAvatar: teams[1].avatar,
                        result: matchupSelection === undefined ? MatchupSelectionType.UNSELECTED : matchupSelection.result
                    });
                });
            }

            headToHeadMatchups.sort(byId());

            // console.log("setting h2h matchups: ", headToHeadMatchups);
            setHeadToHeadMatchups(headToHeadMatchups);
        }

        loadMatchups().catch(console.error);
    }, [league, teams, nflState, matchups, matchupSelections]);

    const [divisions, setDivisions] = useState<number[]>([]);
    useEffect(() => {
        let divisions = new Set<number>();
        teams.forEach(team => {
            divisions.add(team.division);
        });

        setDivisions(Array.from(divisions).sort());
    }, [teams]);

    const [weeks, setWeeks] = useState<number[]>([]);
    useEffect(() => {
        let weeks = new Set<number>();
        headToHeadMatchups.forEach(m => weeks.add(m.week));
        let sortedWeeks = Array.from(weeks).sort((a, b) => a - b);
        setWeeks(sortedWeeks);
    }, [headToHeadMatchups]);

    const [selectedWeek, setSelectedWeek] = useState<number>(0);
    useEffect(() => {
        if (league !== undefined && nflState !== undefined) {
            let isCurrentSeason = nflState.season === league.season;
            let playoff_week = league.settings.playoff_week_start;
            let current_week = isCurrentSeason ? nflState.week : playoff_week;
            setSelectedWeek(Math.min(current_week, playoff_week));
        }
    }, [league, nflState]);

    function onSelectWeek(event: any) {
        let itemClicked = event.target.text;
        setSelectedWeek(parseInt(itemClicked));
    }

    // const [seasonKey, setSeasonKey] = useState("");
    // const [selectionKeyTernaryDash, setSelectionKeyTernaryDash] = useState("");
    useEffect(() => {
        if (headToHeadSelections.length === 0 || urlSearchParams === undefined) {
            return;
        }

        let bits: string[] = [];
        let keyTernary: string[] = [];
        let key36: string[] = [];
        headToHeadSelections.forEach(selection => {
            let matchup = selection as HeadToHeadSelection;
            let bit = matchup.result + "";
            bits.push(bit);
            if (bits.length === 6) {
                if (key36.length > 0) {
                    key36.push("-");
                    keyTernary.push("-");
                }

                // Append to ternary
                let ternary = bits.join("");
                keyTernary.push(ternary);

                // Append to key36
                let bit36 = parseInt(ternary, BIT_POWER).toString(WORD_POWER);
                if (bit36.length === 1) {
                    bit36 = `0${bit36}`;
                }
                key36.push(bit36);

                // Reset bits for week
                bits = [];
            }
        });
        // setSelectionKeyTernaryDash(keyTernary.join(""));

        const seasonKeyUpdate = key36.join("");
        // setSeasonKey(seasonKeyUpdate);
        console.log(`SEASON KEY UPDATE: ${seasonKeyUpdate}`);
        setUrlSearchParams({"key": seasonKeyUpdate}, {replace: true});
    }, [headToHeadSelections, urlSearchParams, setUrlSearchParams]);

    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={12} className="text-center">
                    <h1 className="font-weight-light">Playoffs</h1>
                    <hr/>
                </Col>
            </Row>
            {/*UrlKey: {urlSearchParams.get("key")}<br/>*/}
            {/*loaded: {loadedKey + ""}<br/>*/}
            {/*SeasonKey: {seasonKey}<br/>*/}
            {/*TernaryKey: {selectionKeyTernaryDash}<br/>*/}
            <Row>{divisions.map(division => <DivisionColumn key={division} division={division} teams={teams}/>)}</Row>
            <Row hidden={weeks.length === 0}>
                <h3 className="text-center">Week {selectedWeek}</h3>
                <Col sm={12} className="justify-content-center d-flex">
                    <Pagination size="sm">
                        {weeks.map(week => <Pagination.Item key={week} onClick={onSelectWeek}
                                                            active={week === selectedWeek}
                                                            disabled={week === selectedWeek}>{week}</Pagination.Item>)}
                    </Pagination>
                </Col>
            </Row>
            {weeks.map(week => <PlayoffsMatchupWeek key={week} selectedWeek={selectedWeek} week={week}
                                                    matchups={headToHeadMatchups}
                                                    onMatchupSelection={onMatchupSelection}/>)}
            <Row className="mt-2">
                <TieBreakerLog title="Making Playoffs Tie Breaker Log" tieBreakerLog={tieBreakerLog}/>
            </Row>
            <Row className="mt-2">
                <TieBreakerLog title="Division Winners Tie Breaker Log" tieBreakerLog={divisionTieBreakerLog}/>
            </Row>
            <Row className="mt-2">
                <TieBreakerLog title="First Place Tie Breaker Log" tieBreakerLog={firstPlaceTieBreakerLog}/>
            </Row>
        </Container>
    );
}
