import {useEffect, useState} from "react";
import {
    getAllMatchups,
    getLeague,
    getPlayers,
    getRosterIdToDisplayNameMap,
    getRosterIdToRosterMap,
    getRosters,
    getUserIdToUserMap,
    getUsers,
    Player
} from "../lib/utils/sleeper";

export interface PlayerPositionScore {
    rosterId: number,
    teamDisplayName: string,
    week: number,
    matchupId: string,
    player: Player,
    rosterPosition: string, // FLEX, DB, BN
    playedAsPosition: string, // WR, DE
    fantasyPositions: string[], // [TE, QB], [DB]
    nflPosition: string, // CB, LB
    points: number,
    starter: boolean,
    division: number
}

export function usePlayerPositionScores() {
    async function getScores() {
        let league = await getLeague();
        let playoff_week = league.settings.playoff_week_start;

        let users = await getUsers();
        let rosters = await getRosters();
        let allMatchups = await getAllMatchups(playoff_week);
        let players = await getPlayers();


        let userIdToUserMap = getUserIdToUserMap(users);
        let rosterIdToRosterMap = getRosterIdToRosterMap(rosters);
        let rosterIdToDisplayMap = getRosterIdToDisplayNameMap(rosters, userIdToUserMap);

        //TODO: Fix this map problem
        let playersMap = new Map(Object.entries<Player>(players as any));

        let playerPositionScores: PlayerPositionScore[] = [];

        allMatchups.forEach(matchupWeek => {
            let currentWeek = matchupWeek.week;
            matchupWeek.matchups.forEach((matchup) => {
                Array.from(Object.entries(matchup.players_points)).forEach(([playerId, points]) => {
                    let player = playersMap.get(playerId);
                    if(player === undefined) {
                        throw new Error("Player not found with key: " + playerId);
                    }

                    // Division
                    let roster = rosterIdToRosterMap.get(matchup.roster_id);
                    if(roster === undefined) {
                        throw new Error("Invalid roster key: " + matchup.roster_id);
                    }
                    let division = roster.settings.division;

                    // Is starter?
                    if(matchup.starters !== null) {
                        let starter = matchup.starters.includes(playerId);

                        // Roster Position filled (e.g. FLEX, IDP, WR, QB)
                        let starterIndex = matchup.starters.indexOf(playerId);
                        let rosterPositionIndex = starterIndex === -1 ? league.roster_positions.length - 1 : starterIndex;
                        let rosterPosition = league.roster_positions[rosterPositionIndex];

                        // Player Display Name
                        let teamDisplayName = rosterIdToDisplayMap.get(matchup.roster_id);
                        if(teamDisplayName === undefined) {
                            throw new Error("Team not found with roster id: " + matchup.roster_id);
                        }

                        // Played as [FLEX => .., IDP => ...]
                        let playedAsPosition = ["FLEX", "IDP_FLEX"].includes(rosterPosition) ? player.fantasy_positions[0] : rosterPosition;

                        playerPositionScores.push({
                            rosterId: matchup.roster_id,
                            teamDisplayName: teamDisplayName,
                            week: currentWeek,
                            matchupId: matchup.matchup_id,
                            player: player,
                            points: points,
                            starter: starter,
                            rosterPosition: rosterPosition,
                            fantasyPositions: player.fantasy_positions,
                            playedAsPosition: playedAsPosition,
                            nflPosition: player.position,
                            division: division
                        });
                    }
                });
            });
        });

        return playerPositionScores;
    }

    const [playerPositionScores, setPlayerPositionScores] = useState<PlayerPositionScore[]>([]);
    useEffect(() => {
        getScores().then(setPlayerPositionScores).catch(console.error);
    }, []);

    return playerPositionScores;
}