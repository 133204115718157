import {useEffect, useState} from "react";
import {getRosterIdToDisplayNameMap, getRosters, getUserIdToUserMap, getUsers} from "../lib/utils/sleeper";

export interface TeamIdentifier {
    rosterId: number,
    displayName: string
}

export function useTeamIdentifiers() {
    async function getTeamIdentifiers() {
        let rosters = await getRosters();
        let users = await getUsers();

        let userIdUserMap = getUserIdToUserMap(users);
        let rosterIdToDisplayNameMap = getRosterIdToDisplayNameMap(rosters, userIdUserMap);

        let teams: TeamIdentifier[] = [];
        Array.from(rosterIdToDisplayNameMap.keys()).forEach(rosterId => {
            teams.push({
                rosterId: rosterId,
                displayName: rosterIdToDisplayNameMap.get(rosterId)!,
            });
        });

        return teams;
    }

    const [teamIdentifiers, setTeamIdentifiers] = useState<TeamIdentifier[]>([]);
    useEffect(() => {
        getTeamIdentifiers().then(setTeamIdentifiers).catch(console.error);
    }, []);

    return teamIdentifiers;
}