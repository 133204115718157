import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {useTitle} from "../hooks/common";

export default function Constitution() {
    useTitle("Constitution | FMFFL");

    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={12} className="text-center">
                    <h1 className="font-weight-light">Constitution</h1>
                </Col>
            </Row>
            <hr/>
            <Row>
                <h2>Table Of Contents</h2>
                <nav>
                    <ul>
                        <li><a href="#purpose">1. Purpose</a></li>
                        <li><a href="#commissioners">2. Commissioners Statement</a></li>
                        <li><a href="#governing">3. Governing Body</a></li>
                        <li><a href="#powers">4. Powers of the Commissioners &amp; Rules Committee Executive </a></li>
                        <li><a href="#members">5. League Members</a></li>
                        <li><a href="#rosters">6. Rosters</a></li>
                        <li><a href="#keepers">7. Keepers</a></li>
                        <li><a href="#waiver">8. Waiver Wire / Free Agency</a></li>
                        <li><a href="#trades">9. Trades</a></li>
                        <li><a href="#draft">10. Draft</a></li>
                        <li><a href="#scoring">11. Scoring</a></li>
                        <li><a href="#fines">12. Fines</a></li>
                        <li><a href="#regular">13. Regular Season &amp; Playoff Formats</a></li>
                        <li><a href="#league">14. League Dues and End of Season Prizes</a></li>
                        <li><a href="#changing">15. Changing/Adding Owner Rules</a></li>
                        <li><a href="#rule">16. Rule Changes</a></li>
                        <li><a href="#water">17. Water Bets</a></li>
                        <li><a href="#amendments">18. Ratified Amendments</a></li>
                    </ul>
                </nav>
            </Row>
            <hr/>
            <p><h3 id="purpose">1. Purpose</h3></p>
            <p >This is the constitution of Family Matters Fantasy Football League (FMFFL). This league exists in order to add an  extra element of fun to the weekly excitement of NFL football. This league was founded to play Fantasy Football at  a competitive level, but mostly to have <span >FUN</span>. Every team owner is a vital part of that process and is essential to the  success of the league.</p>
            <p><h3 id="commissioners">2. Commissioners Statement</h3></p>
            <p >&ldquo;The rules set forth in our constitution are designed to keep possession of this league in the hands of the owners. It  is this league&rsquo;s philosophy that the owners control this league. We only act as commissioners, league arbitrators  and official record keepers. Should the need arise, we will act in the best interest of the league and not to one  owner&rsquo;s benefit.&rdquo;</p>
            <p><h3 id="governing">3. Governing Body</h3></p>
            <p>Co-Commissioners: David Cupit II &amp; Jonathan Surls</p>
            <p>Rules Committee Executive and Official Assistant to the Co-Commissioners: Stuart Head</p>
            <p><h3 id="powers">4. Powers of the Commissioners &amp; Rules Committee Executive </h3></p>
            <ul>
                <li>The league will have two Commissioners to help serve as a system of checks and balances and prevent a  feeling of dictatorship among league members. Both Commissioners will have the same responsibilities  and will both review every move.</li>
                <li>The league will have a Rules Committee Executive that will rule on decisions when the two  Commissioners are in a disagreement. If league members have issues with Commissioners decisions  then they will report to the Rules Committee Executive. He will represent the league members and will  work with the Commissioners to resolve any such issues.</li>
                <li>The Commissioners and Rules Committee Executive are sworn to act at all times in the best interests of  the league.</li>
                <li>The Commissioners may deem necessary to remove inactive owners from the league at the end of the  season (see Section 1). The commissioners will not do this unless a team fails to submit a lineup, plays  injured players regularly or does not submit lineups in time. Other events may also be cause for removal  and will be dealt with on a case-by-case basis.</li>
                <li>If one of the Commissioners is involved in a trade then the other Commissioner will review the trade. If  the both Commissioner trade with each other then the Rules Committee Executive will review the trade.</li>
                <li>If a team owner disagrees with the ruling of the commissioner he may appeal the ruling to Rules  Committee Executive.</li>
                <li>The &ldquo;Good of the League&rdquo; general rule: The commissioners have the power to change a &lsquo;loophole&rsquo; rule  during the season. Any such rule will be changed under the &ldquo;good of the league&rdquo; general rule.</li>
            </ul>
            <p><h3 id="members">5. League Members</h3></p>
            <ul>
                <li>Daniel Cupit</li>
                <li>David Cupit, Sr.</li>
                <li>David Cupit II</li>
                <li>Bryce Chastain</li>
                <li>Stuart Head</li>
                <li>Jackson Hulme</li>
                <li>Louis Palomo</li>
                <li>Ryan Palomo</li>
                <li>Mike Staffel</li>
                <li>Joey Surls</li>
                <li>Jonathan Surls</li>
                <li>Mike Surls </li>
            </ul>

            <p><h3 id="rosters">6. Rosters</h3></p>
            <p>Size: Each team will consist of 20 players - 13 starters and 7 bench players.</p>
            <p>Starting Lineups:</p>
            <ul>
                <li>1 Quarterback</li>
                <li>2 Running Backs</li>
                <li>Wide Receivers</li>
                <li>Tight End</li>
                <li>1 Offensive Flex Player (RB/WR/TE)</li>
                <li>1 Kicker</li>
                <li>1 Defensive End</li>
                <li>1 Linebacker</li>
                <li>2 Defensive Backs</li>
                <li>1 Defensive Flex Player (DE/LB/DB)</li>
                <li>1 &ldquo;Injured Reserve&rdquo; spot  </li>
            </ul>
            <ul>
                <li>Starting lineups will be due before the kick-off of the player&rsquo;s game.</li>
                <li>An owner must start a player at every position each week. If he does not have enough players at a certain  position for multiple weeks then he will be &ldquo;fined&rdquo;.</li>
                <li>Only players with the &ldquo;IR&rdquo; designation on Sleeper, will be allowed to be placed in this roster spot. Once a  player&rsquo;s status is changed to Probable, Doubtful or Out, the player must be removed from the &ldquo;IR&rdquo; roster  spot.</li>
            </ul>

            <p><h3 id="keepers">7. Keepers</h3></p>
            <ul>
                <li>Each team will be able to keep 2 offensive players and 1 defensive player from one season to the next.  There is no penalty for keeping players and there will be no maximum amount of years that a player can  be kept.</li>
                <li>A keeper designation date will be set as August 1st(or day of a scheduled offseason meeting) every year.  This date allows for all draft and free agency moves in real life to be made. League members must  designate a keeper by this date or forfeit their right to keep anyone from the previous year&rsquo;s roster.</li>
                <li>Each offensive keeper tag that is not used is worth 50 balls for that league member&rsquo;s team in the next  years draft lottery</li>
                <li>Each defensive keeper tag that is not used is worth 25 balls for that league member&rsquo;s team in the next  years draft lottery</li>
            </ul>
            <p><h3 id="waiver">8. Waiver Wire / Free Agency</h3></p>
            <ul>
                <li>Waiver wire will be an &ldquo;auction style&rdquo;.</li>
                <li>Before the start of the season, all undrafted players will become a free agent.</li>
                <li>Once dropped, a player will become a free agent.</li>
                <li>Each player will be given a player acquisition budget of $100 to use to pick up free agents. Players are  able to roll up to $50 FAB from previous season.</li>
                <li>Players will be able to use their player acquisition budget to place &ldquo;closed bids&rdquo; on free agents in a &rdquo;free  agent auction&rdquo;.</li>
                <li>&ldquo;Free agent auctions&rdquo; will be held every day and will end at 11 AM every morning. The team with the  highest closed bid will acquire that player for the bid that they placed. If the winning bidder bids $10 and  the next highest bidder bids $4, the winning bidder will acquire the player for $10 and not $5.</li>
                <li>Minimum bids for a free agent will be $0</li>
                <li>Bids can be revoked at any time prior to the free agent auction deadline at 11 AM of that day. </li>
                <li>A team must use their player acquisition budget strategically. Once a player acquisition budget is  completely used, the team may not acquire any free agent via the &ldquo;free agent auction&rdquo;. Players will only  be able to be acquired via trade.</li>
                <li>If a team runs out of their budget, they may use draft picks to trade for additional budget after the trade  deadline.</li>
            </ul>
            <p><h3 id="trades">9. Trades</h3></p>
            <ul>
                <li>The player trade deadline will be set for Week 12. Trades must be accepted prior to the end of the final game of Week 12.</li>
                <li>There will not be a trade deadline for draft picks or &ldquo;FAB&rdquo;.</li>
                <li>The player trade season will begin after the Annual Summit meeting (or day of a scheduled offseason  meeting) of every year after the draft lottery has commenced.</li>
                <li>There is no limit on the amount of trades for each team each year.</li>
                <li>Future draft picks can be traded up to two years in advance of the current season. For example, if it is the  2016 season, then you can&rsquo;t trade picks for the 2019 draft.</li>
                <li>Protections can be added to picks. For example, a league member can trade a 1stround pick (top 3  protected). If that pick lands in the top 3 after the lottery, then it converts to a 2ndround pick in that years  draft or a 1stround pick in next years draft, as decided by the other league member. In such an instance,  the other league member must select which pick they would like (2ndin this years draft or 1stin next years  draft) immediately after the lottery presentation (or before the end of that day). When a league member  add protections to their picks, it is understood that the other potential picks involved MAY NOT be  included in any trades until the pick is actually conveyed (ie &ndash; day of the summit)</li>
                <li>Owners cannot exchange actual money or anything else for players. If the commissioner learns of a team  attempting to &ldquo;buy&rdquo; a player, then a fine will be issued.</li>
                <li>Once an owner agrees to a trade, he cannot back out of the trade.</li>
                <li>Each trade, once agreed upon, will be subject to a maximum review time of half a day for the  Commissioners.</li>
                <li>Trades will not be voted upon by league members unless:</li>
                <ul>
                    <li>One Commissioner votes to approve the trade and the other Commissioner votes to reject the  trade. A one-day voting period for league members will be conducted to serve as the  tiebreaker. If the vote is tied at the end of the 24-hour period, then the trade is approved.</li>
                    <li>Both commissioners approve or reject the trade and the Rules Committee Executive appeals  the decision. The Rules Committee Executive can appeal the decision if two or more members  disagree with the ruling of the Commissioners. If such an appeal is made then a member vote  lasting 24 hours will take place. A 2/3 majority vote will be needed to overturn the ruling of the  Commissioners. The Rules Committee has to appeal the decision within 48 hours for a vote to  occur.</li>
                    <li>If a vote occurs then all members, including the Rules Committee Executive and Commissioners  may participate.</li>
                </ul>
                <li>If a Commissioner votes to reject a trade, he must post his reasoning on the message board. Some valid  reasons why a trade would be vetoed:</li>
                <ul>
                    <li>Collusion</li>
                    <li>Unfair trade by a large margin</li>
                </ul>
                <li>Some reasons why a trade will not be vetoed:</li>
                <ul>
                    <li>Player involved in trade gets hurt</li>
                    <li>Unfair by a small margin</li>
                    <li>Owner involved in trade wants to back out</li>
                </ul>
            </ul>
            <p><h3 id="draft">10. Draft</h3></p>
            <ul>
                <li>Draft is 17 rounds every year.</li>
                <li>Drafting of players will be done in a fixed order for the first round every year, then a serpentine order  starting in round 2 with the team that wins the lottery. </li>
                <li>Draft position will be determined by lottery that is held at the Annual Summit meeting (or day of a  scheduled offseason meeting) and facilitated by the Commissioners.</li>
                <li>Draft lottery odds will be determined by the by final playoff standings except for the two teams in the  championship who will have the two worst odds of obtaining the number one pick in the lottery.  - Breakdown for number of balls each team will have in the lottery:</li>
                <ul>
                    <li>12thplace - 250 balls (25.0% chance of obtaining #1 pick)</li>
                    <li>11thplace - 199 balls (19.9%)</li>
                    <li>10thplace - 156 balls (15.6%)</li>
                    <li>9thplace - 119 balls (11.9%)</li>
                    <li>8thplace - 88 balls (8.8%)</li>
                    <li>7thplace - 63 balls (6.3%)</li>
                    <li>6thplace - 43 balls (4.3%)</li>
                    <li>5thplace - 28 balls (2.8%)</li>
                    <li>4thplace - 21 balls (2.1%)</li>
                    <li>3rdplace - 14 balls (1.4%)</li>
                    <li>2ndplace -11 draft spot</li>
                    <li>1stplace &ndash; last draft spot</li>
                </ul>
                <li>Two minutes per pick will be allocated during the draft. Managers are encouraged to expedite their picks  in later rounds as much as possible.</li>
                <li>Each offensive keeper tag that is not used is worth 50 balls for that league member&rsquo;s team in the next  years draft lottery</li>
                <li>Each defensive keeper tag that is not used is worth 25 balls for that league member&rsquo;s team in the next  years draft lottery</li>
            </ul>

            <p><h3 id="scoring">11. Scoring</h3></p>

            <p><strong>Offensive Scoring:</strong></p>
            <p>Rushing Yards 0.1 point for every 1 yards</p>
            <p>Rushing Attempts 0.1 point for every rush attempt</p>
            <p>100-199 Rushing Yards 3 point bonus</p>
            <p>200+ Rushing Yards 5 point bonus</p>
            <p>40+ Yard Rushing TD 1 point bonus</p>
            <p>50+ Yard Rushing TD 3 point bonus</p>
            <p>Rushing Touchdowns 6</p>
            <p>Reception Yards 0.1 point for every 1 yards</p>
            <p>Points per Reception 0.5</p>
            <p>100-199 Receiving Yards 3 point bonus</p>
            <p>200+ Receiving Yards 5 point bonus</p>
            <p>40+ Yard Receiving TD 1 point bonus</p>
            <p>50+ Yard Receiving TD 3 point bonus</p>
            <p>Reception Touchdowns 6</p>
            <p>Passing Yards 0.2 point for every 5 yards</p>
            <p>300-399 Passing Yards 3 point bonus</p>
            <p>400+ Passing Yards 5 point bonus</p>
            <p>40+ Yard Passing TD 1 point bonus</p>
            <p>50+ Yard Passing TD 3 point bonus</p>
            <p>Passing Touchdowns 6</p>
            <p>Interceptions -3</p>
            <p>Every Time Sacked -0.5</p>
            <p>Fumbles Lost -3</p>
            <p>2-point Conversions 2</p>
            <p>Return Touchdowns 6</p>
            <p><strong>Defensive Player Scoring:</strong></p>
            <p>Tackle Solo 1</p>
            <p>Assisted Tackle 0.5</p>
            <p>Tackle For Loss 1</p>
            <p>10+ Tackles 1 point bonus</p>
            <p>Sack 1.5</p>
            <p>QB Hit 0.5</p>
            <p>Interception 3</p>
            <p>Fumble Force 1.5</p>
            <p>Fumble Recovery 3</p>
            <p>Defensive Touchdown 6</p>
            <p>Safety 2</p>
            <p>Pass Defended 1.5</p>
            <p>Blocked Punt, FG or PAT 2</p>
            <p><h3 id="fines">12. Fines</h3></p>

            <ul>
                <li>Fines are issued to keep the integrity of the league and to promote fun. Please note that they will be used  as a last resort.</li>
                <li>Fines issued are subject to the approval of both Commissioners. Some actions that might warrant a fine: </li>
                <ul>
                    <li>Collusion</li>
                    <li>Dump Trading</li>
                    <li>Not being active in the league. Active includes, but is not limited to the following:</li>
                    <ul>
                        <li>Responding to trades in a timely manner</li>
                        <li>Responding to votes in a timely manner</li>
                        <li>Setting an appropriate lineup every week. This is reserved for egregious non-attempts  that are not &ldquo;strategic&rdquo; as determined by a league vote. A majority vote prevails.</li>
                    </ul>
                </ul>
                <li>Changing or tampering other league member&rsquo;s lineups with or without permission.</li>
                <li>Managing your team in a way that is purposefully detrimental to another league member&rsquo;s team (This  does include transactions). Fines will be as follows:</li>
                <ul>
                    <li>1st offense</li>
                    <ul>
                        <li>A warning will be issued.</li>
                    </ul>
                    <li>2nd offense</li>
                    <ul>
                        <li>The league member shall be watered by every other league member in attendance at  next years draft</li>
                    </ul>
                    <li>3rd offense</li>
                    <ul>
                        <li>Will result in a member vote on other possible fines and possible league ban.</li>
                        <ul>
                            <li>Each year any strikes will be wiped from the board and every owner will start with  zero every year.</li>
                            <li>The Rules Committee Executive can appeal the decision if two or more members  (other than the member that is fined) disagree with the ruling of the Commissioners.</li>
                        </ul>
                        <li>If such an appeal is made then a member vote lasting 24 hours will take place. A 2/3  majority vote will be needed to overturn the ruling of the Commissioners. The Rules Committee has to appeal the decision within 48 hours for a vote to occur.</li>
                    </ul>
                </ul>
            </ul>
            <p><h3 id="regular">13. Regular Season &amp; Playoff Formats</h3></p>

            <p>Regular Season: </p>
            <ul>
                <li>14 weeks</li>
                <li>Every team will play every other team at least once and teams within the division twice.</li>
                <li>There will be four divisions:</li>
                <ul>
                    <li>Division 1:</li>
                    <ul>
                        <li>Jonathan Surls</li>
                        <li>Daniel Cupit</li>
                        <li>Louis Palomo </li>
                    </ul>
                    <li>Division 2</li>
                    <ul>
                        <li>Stuart Head</li>
                        <li>David Cupit Sr.</li>
                        <li>Jackson Hulme</li>
                    </ul>
                    <li>Division 3</li>
                    <ul>
                        <li>David Cupit II</li>
                        <li>Joey Surls</li>
                        <li>Preston Hamilton</li>
                    </ul>
                    <li>Division 4</li>
                    <ul>
                        <li>Mike Surls</li>
                        <li>Mike Staffel</li>
                        <li>Ryan Palomo</li>
                    </ul>
                </ul>
            </ul>
            <p>Playoffs: </p>
            <ul>
                <li>Weeks 15, 16, 17 will be used for playoff weeks.</li>
                <li>Each Division winner is guaranteed a playoff spot</li>
                <li>The remaining 4 playoff spots are determined by best record utilizing tie breakers if needed.</li>
                <li>The 8 playoff teams are then seeded by record using tie breakers as necessary</li>
                <li>The 1 seed will play the 8 seed. 2 seed vs 7 seed; 3 seed vs 6 seed and 4 seed vs 5 seed</li>
                <li>The top seed within will receive a 6 point advantage in their week 14 playoff matchup if the choose to  play the eight seed. The top seed also has the option of selecting another opponent to play &ndash; in doing so,  they forfeit their 6 point advantage. The 6 point advantage only applies to week 14.</li>
                <li>If the top seed chooses to play another opponent, then the 8thseed is swapped in the playoff bracket with  the team the top seed elects to play.</li>
                <li>Final Standings will be determined through continued play through the playoffs.</li>
                <ul>
                    <li>The four teams that lose in the first round of the championship playoff bracket will play each  other in in the consolation bracket with the highest ranked losing seed playing the lowest rank  losing seed.</li>
                    <li>The two teams that lose in the second round of the championship playoffs will play the two  winning teams from the first week of the consolation bracket. The two highest ranked seeds of  these four teams will play for 3rdand 4thplace. The other matchup will play for 5thand 6th.</li>
                    <li>The two losing teams from the first week of the consolation bracket will face each other for 7thand 8thplace.</li>
                    <li>The two winners of the cow suit bracket will play each other in a two week cumulative score  matchup for 9thand 10thplace.</li>
                    <li>The two losers of the cow suit bracket will play each other in a two week cumulative score  matchup for 11thand 12thplace.</li>
                </ul>
            </ul>
            <p>Tiebreakers: </p>
            <ul>
                <li>For teams that have the same record at the end of the season, then head to head record will be used as a  tiebreaker. If multiple teams have the same record, then the tie breaker is the head to head matchup  record against all teams, unless it is to determine a division winner for the purposes of a guaranteed  playoff spot. If that is the case, the Division Winner shall be determined by head to head record between  the eligible Division Winning teams. After the Division Winner is determined, they shall be considered as  in the playoffs and shall not be included in any future tiebreaker head to head records. If teams have  played a different number of games in this tiebreaker, then win percentage of head to head matchups  shall be used.</li>
                <li>Total points scored on the season will be used as a second tiebreaker for teams that are tied.</li>
                <li>Third tiebreaker will be a coin flip.</li>
            </ul>
            <p><h3 id="league">14. League Dues and End of Season Prizes</h3><strong></strong></p>
            <ul>
                <li>League Dues will be $50 per team. Dues will be paid at the time of the draft.</li>
                <li>Prize Payouts:</li>
                <ul>
                    <li>1stPlace: $300, Championship Belt, 12thdraft pick</li>
                    <li>2ndPlace: $100, 11thdraft pick</li>
                    <li>3rdPlace: $80, 10thdraft odds </li>
                    <li>4thPlace: $60, 9thdraft odds</li>
                    <li>5thPlace: $30, 8thdraft odds</li>
                    <li>6thPlace: $20 7thdraft odds</li>
                    <li>7thPlace: $10, 6thdraft odds</li>
                    <li>8thPlace: 5thdraft odds</li>
                    <li>9thPlace: 1stdraft odds</li>
                    <li>10thPlace: 2nddraft odds</li>
                    <li>11thPlace: 3rddraft odds</li>
                    <li>12thPlace: 4thdraft odds</li>
                </ul>
                <li>Cash payouts will be paid prior to the start of the next League Summt.</li>
                <li>Winner will also be awarded the Family Matters Fantasy Football Championship Belt. This belt will serve  as your bragging rights to your fellow members.</li>
                <li>League Champions may also elect to purchase a championship ring from their winnings </li>
                <li>Championship belt will be passed from winner to winner every year.</li>
                <li>Winner is responsible for the Championship belt. Loss of the belt will result in a replacement belt to be  purchased by that person by the time the belt will be transferred.</li>
                <li>Shortened Football Season</li>
                <ul>
                    <li>If in the event a worldwide event happens forcing the NFL to end the season early (for example  &ndash; Covid-19), the standings at that time would be the final standings for the FMFFL season and  the first place team would be the champion if seven (7) weeks have been completed. An  asterisk will be placed on the championship belt. If the season is cancelled before the  completion of week seven, no champion is declared and all money is refunded. The standings  at that time would still be used for lottery odds for the following season.</li>
                </ul>
            </ul>

            <p><h3 id="changing">15. Changing/Adding Owner Rules</h3></p>

            <ul>
                <li>If for some reason an owner will not be returning to the league, a new owner will be chosen to take over  the team.</li>
                <li>He will inherit whatever conditions for the team he is taking over. If it is determined that moves were  made to help others before the previous owner left the league then those transactions can be reversed by  the Commissioners.</li>
                <li>Keepers will be chosen from the team the owner is taking over if the owner isn&rsquo;t selected by July 1st.</li>
                <li>Draft position will be the position of the team owner is taking over.</li>
            </ul>
            <p><h3 id="rule">16. Rule Changes</h3></p>
            <ul>
                <li>Possible rule changes will be addressed at the end of each season.</li>
                <li>Rule changes will be voted upon by all league members before the draft of the next season. </li>
                <li>A change to any part of this document will be considered a rule change.</li>
            </ul>
            <p><h3 id="water">17. Water Bets</h3></p>
            <ul>
                <li>Water bets are a fun way for league members to settle disputes that arise throughout the season.</li>
                <li>The RCE will be responsible for tracking such bets.</li>
                <li>All bets made throughout the season shall be paid off at the next years draft. </li>
            </ul>
            <p><h3 id="amendments">18. Ratified Amendments</h3></p>
            <ul>
                <li>Amendment 1: Trade Deadline Adjusted to Sleeper Default Enforcement</li>
                <ul>
                    <li>Previous Language: The player trade deadline will be set for the Wednesday (at 10 pm) after week 12 and completion of  regular season.</li>
                    <li>New Language: The player trade deadline will be set for Week 12. Trades must be accepted prior to the end of the final game of Week 12.</li>
                    <li>Date: November 21, 2023</li>
                    <li>Yea: jsurls, BrosthatBash, DanielCupit, DCupit2, mikestaffel, stucabesa, Daboys2021, jhulme, RyanPalomo</li>
                    <li>Nay: N/A</li>
                </ul>
            </ul>
        </Container>
    );
}
