import React, {useEffect, useState} from "react";
import {Col, Container, Row, Table} from "react-bootstrap";
import {useTitle} from "../hooks/common";
import {useLeague, useMatchups, useNflState, useRosters, useUsers} from "../hooks/sleeper";
import {AVATAR_DEFAULT_URL_PREFIX, getRosterIdToRosterMap, getUserIdToUserMap} from "../lib/utils/sleeper";
import TeamAvatar from "../components/TeamAvatar";

interface EliminationWeek {
    week: number,
    displayName: string,
    avatar: string,
    points: number
}

interface AliveTeam {
    displayName: string,
    avatar: string,
}

export default function Guillotine() {
    useTitle("Guillotine | FMFFL");

    const [aliveTeams, setAliveTeams] = useState<AliveTeam[]>([]);
    const [eliminationWeeks, setEliminationWeeks] = useState<EliminationWeek[]>([]);

    const matchups = useMatchups();
    const nflState = useNflState();
    const league = useLeague();
    const users = useUsers();
    const rosters = useRosters();

    useEffect(() => {
        if (league === undefined || nflState === undefined || matchups.length === 0 || users.length === 0 || rosters.length === 0 || users.length === 0) {
            return;
        }
        // Determine Current Week
        let isCurrentSeason = nflState.season === league.season;
        let eliminationWeek = rosters.length;
        // let eliminationWeek = rosters.length - 4;
        let currentWeek = isCurrentSeason ? nflState.week : eliminationWeek;

        // Setup maps
        let userIdUserMap = getUserIdToUserMap(users);
        let rosterIdToRostersMap = getRosterIdToRosterMap(rosters);

        let eliminationWeeks: EliminationWeek[] = [];
        let eliminatedRosterIds = new Set<number>();
        matchups.filter(m => m.week < currentWeek)
            .forEach((matchup) => {
                let weekNumber = matchup.week;
                let lowestScore = -1;
                let rosterId = -1;
                matchup.matchups.filter(m => !eliminatedRosterIds.has(m.roster_id)).forEach(m => {
                   if(lowestScore === -1 || Math.min(lowestScore, m.points) === m.points) {
                       lowestScore = m.points;
                       rosterId = m.roster_id;
                   }
                });

                let roster = rosterIdToRostersMap.get(rosterId);
                if(roster === undefined) {
                    throw new Error(`Unable to find roster with id ${rosterId}`);
                }
                let user = userIdUserMap.get(roster.owner_id);
                if(user === undefined) {
                    throw new Error(`Unable to find user for id: ${roster.owner_id}`);
                }

                let avatar = (user.metadata.avatar !== undefined) ? user.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + user.avatar;
                let displayName = user.display_name;

                eliminationWeeks.push({
                    week: weekNumber,
                    displayName: displayName,
                    avatar: avatar,
                    points: lowestScore,
                });

                eliminatedRosterIds.add(rosterId);
            });
        setEliminationWeeks(eliminationWeeks);

        let aliveTeams: AliveTeam[] = [];
        rosters.filter(r => !eliminatedRosterIds.has(r.roster_id)).forEach(r => {
            let rosterId = r.roster_id;

            let roster = rosterIdToRostersMap.get(rosterId);
            if(roster === undefined) {
                throw new Error(`Unable to find roster with id ${rosterId}`);
            }
            let user = userIdUserMap.get(roster.owner_id);
            if(user === undefined) {
                throw new Error(`Unable to find user for id: ${roster.owner_id}`);
            }

            let avatar = (user.metadata.avatar !== undefined) ? user.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + user.avatar;
            let displayName = user.display_name;

            aliveTeams.push({
                displayName: displayName,
                avatar: avatar
            });
        });
        setAliveTeams(aliveTeams);
    }, [matchups, nflState, league, rosters, users]);

    return (
        <Container className="px-4 my-5 pt-3">
            <Row className="text-center">
                <h1>Guillotine</h1>
                <h6>"Last man Standing"</h6>
                <hr/>
            </Row>
            <Row className="text-center" hidden={aliveTeams.length !== 1}>
                <h2>Survivor!</h2>
                <Row className="text-center m-auto">
                    {
                        aliveTeams.map(aliveTeam =>
                            <h5 key={aliveTeam.displayName}>
                            <TeamAvatar displayName={aliveTeam.displayName} avatar={aliveTeam.avatar} avatarLeft={true} highlight={false}/>
                            </h5>
                        )
                    }
                </Row>
            </Row>
            <Row className="text-center" hidden={aliveTeams.length === 1}>
                <h2>Still Alive</h2>
                <Row className="text-center m-auto">
                    {
                        aliveTeams.map(aliveTeam =>
                            <Col xs={6} key={aliveTeam.displayName} className="text-start">
                                <TeamAvatar displayName={aliveTeam.displayName} avatar={aliveTeam.avatar} avatarLeft={true} highlight={false}/>
                            </Col>
                        )
                    }
                </Row>
            </Row>
            <br/>
            <Row className="text-center">
                <h2>Eliminated</h2>
            </Row>
            <Table striped bordered size="sm" className="nowrap">
                <thead>
                <tr className="text-center">
                    <th>Week</th>
                    <th>Team</th>
                    <th>Score</th>
                </tr>
                </thead>
                <tbody>
                {
                    eliminationWeeks.map(eliminationWeek =>
                        <tr key={eliminationWeek.week}>
                            <td>Week {eliminationWeek.week}</td>
                            <td className="text-start"><TeamAvatar displayName={eliminationWeek.displayName}
                                                                   avatar={eliminationWeek.avatar} avatarLeft={true}
                                                                   highlight={false}/></td>
                            <td>{eliminationWeek.points}</td>
                        </tr>
                    )
                }
                </tbody>
            </Table>

        </Container>
    );
}