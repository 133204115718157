import React from 'react';
import {Card, Col} from "react-bootstrap";
import TeamAvatar from "./TeamAvatar";

export interface MatchupContainerProps {
    title: string,
    week: number,
    home: string,
    homeAvatar: string,
    away: string,
    awayAvatar: string,
    metric: number,
    selectedDisplayName: string
}

function MatchupContainer(props: MatchupContainerProps) {
    return (
        <Card className="text-center small-box">
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>
                    <Col sm={12} className="mb-2">
                        <TeamAvatar avatar={props.homeAvatar} displayName={props.home} avatarLeft={true} highlight={props.selectedDisplayName === props.home}/>/<TeamAvatar avatar={props.awayAvatar} displayName={props.away} avatarLeft={false} highlight={props.selectedDisplayName === props.away}/>
                    </Col>
                    <Col sm={12}><b>Week {props.week}</b></Col>
                    <Col sm={12}>{props.metric.toFixed(2)}</Col>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default MatchupContainer;