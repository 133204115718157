export const playoff_report_text =
    [
        "Team: JSurls10 -- Made Playoffs: 7.18%[1205312], Bubble Playoffs: 20.18%[3386304], Missed Playoffs: 72.63%[12185600]",
        "    ==== 527 different ways team tied for playoffs ====",
        "    ---- Tied Group 5.09%[172224] : Daboys2021,DavidSenior,JSurls10",
        "    ---- Tied Group 5.02%[169824] : DavidSenior,JSurls10",
        "    ---- Tied Group 4.97%[168192] : Daboys2021,JSurls10,Lpalomo",
        "    ---- Tied Group 4.91%[166344] : Batman57,JSurls10,Lpalomo",
        "    ---- Tied Group 4.89%[165504] : JSurls10,Lpalomo",
        "Team: DanielCupit -- Made Playoffs: 98.65%[16549888], Bubble Playoffs: 1.35%[227136], Missed Playoffs: 0.00%[192]",
        "    ==== 471 different ways team tied for playoffs ====",
        "    ---- Tied Group 7.57%[17192] : Batman57,Daboys2021,DanielCupit,Lpalomo",
        "    ---- Tied Group 4.75%[10796] : Daboys2021,DanielCupit,DavidSenior,Lpalomo",
        "    ---- Tied Group 3.66%[8316] : Batman57,Daboys2021,DanielCupit,DavidSenior",
        "    ---- Tied Group 2.85%[6472] : Daboys2021,DanielCupit,DavidSenior,Lpalomo,mikestaffel",
        "    ---- Tied Group 2.82%[6416] : Batman57,Daboys2021,DanielCupit,Lpalomo,mikestaffel",
        "Team: stucabesa -- Made Playoffs: 0.00%[0], Bubble Playoffs: 0.00%[64], Missed Playoffs: 100.00%[16777152]",
        "    ==== 1 different ways team tied for playoffs ====",
        "    ---- Tied Group 100.00%[64] : Daboys2021,DavidSenior,JSurls10,Lpalomo,stucabesa",
        "Team: Daboys2021 -- Made Playoffs: 32.10%[5385728], Bubble Playoffs: 32.45%[5444016], Missed Playoffs: 35.45%[5947472]",
        "    ==== 563 different ways team tied for playoffs ====",
        "    ---- Tied Group 7.34%[399648] : Daboys2021,DavidSenior",
        "    ---- Tied Group 7.23%[393600] : Daboys2021,Lpalomo",
        "    ---- Tied Group 6.57%[357624] : Batman57,Daboys2021,Lpalomo",
        "    ---- Tied Group 6.52%[355080] : Daboys2021,DavidSenior,Lpalomo",
        "    ---- Tied Group 6.52%[354744] : Batman57,Daboys2021,DavidSenior",
        "Team: DCupit2 -- Made Playoffs: 100.00%[16777166], Bubble Playoffs: 0.00%[50], Missed Playoffs: 0.00%[0]",
        "    ==== 18 different ways team tied for playoffs ====",
        "    ---- Tied Group 24.00%[12] : DCupit2,Daboys2021,DanielCupit,DavidSenior,Lpalomo,RyanPalomo,jhulme,mikestaffel",
        "    ---- Tied Group 12.00%[6] : DCupit2,Daboys2021,DanielCupit,DavidSenior,Lpalomo,RyanPalomo,jhulme,jsurls,mikestaffel",
        "    ---- Tied Group 8.00%[4] : DCupit2,Daboys2021,DanielCupit,DavidSenior,Lpalomo,jhulme,mikestaffel",
        "    ---- Tied Group 8.00%[4] : DCupit2,Daboys2021,DavidSenior,Lpalomo,RyanPalomo,jhulme,mikestaffel",
        "    ---- Tied Group 6.00%[3] : DCupit2,Daboys2021,DanielCupit,DavidSenior,Lpalomo,RyanPalomo,jsurls,mikestaffel",
        "Team: jhulme -- Made Playoffs: 99.04%[16615324], Bubble Playoffs: 0.96%[161692], Missed Playoffs: 0.00%[200]",
        "    ==== 407 different ways team tied for playoffs ====",
        "    ---- Tied Group 8.07%[13052] : Daboys2021,DavidSenior,Lpalomo,jhulme",
        "    ---- Tied Group 3.58%[5796] : Daboys2021,DavidSenior,Lpalomo,RyanPalomo,jhulme",
        "    ---- Tied Group 3.46%[5588] : Batman57,Daboys2021,DavidSenior,jhulme",
        "    ---- Tied Group 2.99%[4832] : Batman57,DavidSenior,Lpalomo,RyanPalomo,jhulme",
        "    ---- Tied Group 2.61%[4224] : Batman57,DavidSenior,Lpalomo,jhulme",
        "Team: mikestaffel -- Made Playoffs: 92.63%[15540684], Bubble Playoffs: 6.69%[1122060], Missed Playoffs: 0.68%[114472]",
        "    ==== 470 different ways team tied for playoffs ====",
        "    ---- Tied Group 3.53%[39600] : Daboys2021,mikestaffel",
        "    ---- Tied Group 3.44%[38592] : Daboys2021,Lpalomo,mikestaffel",
        "    ---- Tied Group 3.32%[37248] : Batman57,Lpalomo,mikestaffel",
        "    ---- Tied Group 3.26%[36576] : Batman57,Daboys2021,mikestaffel",
        "    ---- Tied Group 3.18%[35712] : Daboys2021,DavidSenior,mikestaffel",
        "Team: Batman57 -- Made Playoffs: 33.46%[5614448], Bubble Playoffs: 29.53%[4955000], Missed Playoffs: 37.00%[6207768]",
        "    ==== 525 different ways team tied for playoffs ====",
        "    ---- Tied Group 7.36%[364848] : Batman57,DavidSenior,Lpalomo",
        "    ---- Tied Group 7.22%[357624] : Batman57,Daboys2021,Lpalomo",
        "    ---- Tied Group 7.16%[354744] : Batman57,Daboys2021,DavidSenior",
        "    ---- Tied Group 6.66%[330048] : Batman57,Daboys2021",
        "    ---- Tied Group 6.23%[308664] : Batman57,Lpalomo",
        "Team: jsurls -- Made Playoffs: 99.22%[16647084], Bubble Playoffs: 0.78%[130052], Missed Playoffs: 0.00%[80]",
        "    ==== 401 different ways team tied for playoffs ====",
        "    ---- Tied Group 5.63%[7316] : Daboys2021,DavidSenior,Lpalomo,jsurls",
        "    ---- Tied Group 3.93%[5116] : Batman57,Daboys2021,DavidSenior,jsurls",
        "    ---- Tied Group 3.17%[4128] : Batman57,Daboys2021,Lpalomo,RyanPalomo,jsurls",
        "    ---- Tied Group 2.88%[3740] : Daboys2021,DavidSenior,Lpalomo,RyanPalomo,jsurls",
        "    ---- Tied Group 2.45%[3184] : Batman57,Daboys2021,Lpalomo,jsurls",
        "Team: RyanPalomo -- Made Playoffs: 91.36%[15327648], Bubble Playoffs: 7.54%[1264672], Missed Playoffs: 1.10%[184896]",
        "    ==== 534 different ways team tied for playoffs ====",
        "    ---- Tied Group 2.66%[33648] : Batman57,DavidSenior,RyanPalomo",
        "    ---- Tied Group 2.63%[33264] : DavidSenior,Lpalomo,RyanPalomo",
        "    ---- Tied Group 2.62%[33120] : Daboys2021,Lpalomo,RyanPalomo",
        "    ---- Tied Group 2.57%[32472] : Lpalomo,RyanPalomo",
        "    ---- Tied Group 2.52%[31904] : Batman57,Daboys2021,Lpalomo,RyanPalomo",
        "Team: DavidSenior -- Made Playoffs: 32.27%[5414320], Bubble Playoffs: 31.33%[5256264], Missed Playoffs: 36.40%[6106632]",
        "    ==== 563 different ways team tied for playoffs ====",
        "    ---- Tied Group 7.60%[399648] : Daboys2021,DavidSenior",
        "    ---- Tied Group 6.94%[364848] : Batman57,DavidSenior,Lpalomo",
        "    ---- Tied Group 6.76%[355080] : Daboys2021,DavidSenior,Lpalomo",
        "    ---- Tied Group 6.75%[354744] : Batman57,Daboys2021,DavidSenior",
        "    ---- Tied Group 5.85%[307672] : DavidSenior,Lpalomo",
        "Team: Lpalomo -- Made Playoffs: 32.34%[5426224], Bubble Playoffs: 31.29%[5249848], Missed Playoffs: 36.37%[6101144]",
        "    ==== 563 different ways team tied for playoffs ====",
        "    ---- Tied Group 7.50%[393600] : Daboys2021,Lpalomo",
        "    ---- Tied Group 6.95%[364848] : Batman57,DavidSenior,Lpalomo",
        "    ---- Tied Group 6.81%[357624] : Batman57,Daboys2021,Lpalomo",
        "    ---- Tied Group 6.76%[355080] : Daboys2021,DavidSenior,Lpalomo",
        "    ---- Tied Group 5.88%[308664] : Batman57,Lpalomo"
    ];