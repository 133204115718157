import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ApplicationNavbar from "./components/ApplicationNavbar";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./routes/Home";
import BigBoard from "./routes/BigBoard";
import Stu from "./routes/Stu";
import PowerRankings from "./routes/PowerRankings";
import Constitution from "./routes/Constitution";
import ReactGA from "react-ga4";
import Playoffs from "./routes/Playoffs";
import Team from "./routes/Team";
import Guillotine from "./routes/Guillotine";
import PlayoffReport from "./routes/PlayoffReport";

// Only track on fmffl.net
if (document.location.hostname.search("fmffl.net") !== -1) {
    ReactGA.initialize("G-EK4D7R3TRK");
}

export default function App() {
    return (
        <Router>
            <div className="App d-flex flex-column">
                <header>
                    <ApplicationNavbar/>
                </header>
                <main>
                    <Routes>
                        <Route path="/Constitution" element={<Constitution/>} />
                        <Route path="/bigboard" element={<BigBoard />}/>
                        <Route path="/guillotine" element={<Guillotine />}/>
                        <Route path="/team/:teamId" element={<Team />}/>
                        <Route path="/playoffs" element={<Playoffs />}/>
                        <Route path="/playoffreport" element={<PlayoffReport />}/>
                        <Route path="/powerrankings" element={<PowerRankings />}/>
                        <Route path="/stu" element={<Stu />}/>
                        <Route path="/" element={<Home />}/>
                    </Routes>
                </main>
            </div>
        </Router>
    );
}
