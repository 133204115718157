import {getItem, putItem} from "./localStorageHelper";
import {Matchups} from "../../hooks/sleeper";

// export const LEAGUE_ID_2023 = "987024866253606912"; // your league Id
export const LEAGUE_ID = "1124854104443588608"; // your league Id

interface SleeperApi {
    nfl: ApiEndpoint,
    league: ApiEndpoint,
    rosters: ApiEndpoint,
    users: ApiEndpoint,
    players: ApiEndpoint
}

interface ApiEndpoint {
    endpoint: string,
    cacheDuration: number,
    storageKey: string
}

export interface Player {
    player_id: string,
    position: string,
    last_name: string,
    first_name: string,
    full_name: string,
    fantasy_positions: string[],
    height: number,
    weight: number,
    age: number,
    team: string,
    status: string,
    years_exp: number
}

interface UserMetadata {
    team_name: string,
    avatar: string,
}

export interface User {
    user_id: string,
    username: string,
    display_name: string,
    avatar: string,
    metadata: UserMetadata,
    team_name: string,
    is_owner: string,
}

interface RosterSettings {
    division: number,
    fpts: number,
    fpts_against: number,
    fpts_against_decimal: number,
    fpts_decimal: number,
    losses: number,
    ties: number,
    total_moves: number,
    waiver_budget_used: number,
    waiver_position: number,
    wins: number,
}

export interface Roster {
    starters: string[],
    settings: RosterSettings,
    roster_id: number,
    reserve: string[],
    players: string[],
    owner_id: string,
    league_id: string,

}

export interface NflState {
    week: number, // week
    season_type: string, // pre, post, regular
    season_start_date: string, // regular season start
    season: number, // current season
    previous_season: number,
    leg: number, // week of regular season
    league_season: number, // active season for leagues
    league_create_season: number, // flips in December
    display_week: number // Which week to display in UI, can be different than week
}

export interface League {
    name: string,
    season: number,
    previous_league_id: string,
    league_id: string,
    settings: LeagueSettings,
    roster_positions: string[]
}

export interface LeagueSettings {
    playoff_teams: number,
    trade_deadline: number,
    waiver_day_of_week: number,
    playoff_week_start: number,
    num_teams: number,
    divisions: number,
    waiver_budget: number
}

export interface Matchup {
    starters: string[],
    roster_id: number,
    players: string[],
    matchup_id: string,
    points: number,
    custom_points: number | undefined,
    starters_points: number[],
    players_points: Map<string, number>
}

export const AVATAR_DEFAULT_URL_PREFIX = "https://sleepercdn.com/avatars/thumbs/";

const MIN_5 = 60000 * 5;
const HOUR_1 = 60000 * 60;

const LEAGUE_KEY = "LEAGUE";
const NFL_STATE_KEY = "NFL_STATE";
const PLAYERS_KEY = "PLAYERS";
const ROSTERS_KEY = "ROSTERS";
const USERS_KEY = "USERS";

const leagueEndpoint = `https://api.sleeper.app/v1/league/${LEAGUE_ID}`;
const playersEndpoint = `https://api.sleeper.app/v1/players/nfl`;
const stateEndpoint = `https://api.sleeper.app/v1/state`;

const sleeperApi: SleeperApi = {
    nfl: {endpoint: stateEndpoint + '/nfl', cacheDuration: HOUR_1, storageKey: NFL_STATE_KEY},
    players: {endpoint: playersEndpoint, cacheDuration: HOUR_1, storageKey: PLAYERS_KEY},
    league: {endpoint: leagueEndpoint, cacheDuration: HOUR_1, storageKey: LEAGUE_KEY},
    rosters: {endpoint: leagueEndpoint + '/rosters', cacheDuration: MIN_5, storageKey: ROSTERS_KEY},
    users: {endpoint: leagueEndpoint + '/users', cacheDuration: MIN_5, storageKey: USERS_KEY}
};

export const getRosters = async () => {
    return await getFromSleeper<Roster[]>(sleeperApi.rosters);
};

export const getUsers = async () => {
    return await getFromSleeper<User[]>(sleeperApi.users);
};

export const getPlayers = async () => {
    return await getFromSleeper<Map<string, Player>>(sleeperApi.players);
};

export const getNflState = async () => {
    return await getFromSleeper<NflState>(sleeperApi.nfl);
};

export const getLeague = async () => {
    return await getFromSleeper<League>(sleeperApi.league);
};

export const getMatchups = async (week: number) => {
    const response = await fetch(`${leagueEndpoint}/matchups/${week}`);
    return await response.json() as Matchup[];
};

export async function getAllMatchups(playoffWeek: number) {
    let matchups: Matchups[] = [];
    let promises = [];
    for (let week = 1; week < playoffWeek; week++) {
        promises.push(
            getMatchups(week)
                .then(result => matchups.push({week: week, matchups: result}))
        );
    }
    await Promise.all(promises).catch(console.error);

    matchups.sort((a, b) => {
        if (a.week < b.week) {
            return -1;
        }
        if (b.week < a.week) {
            return 1;
        }
        return 0;
    });

    return matchups;
}

/**
 * Generic method to fetch an endpoint from Sleeper or from localstorage
 *
 * @param api
 * @returns {Promise<any>}
 */
const getFromSleeper = async <T>(api: ApiEndpoint) => {
    const storageKey = api.storageKey;
    const cacheDuration = api.cacheDuration;
    const endpoint = api.endpoint;

    let data: T | undefined = getItem(storageKey, cacheDuration);

    if (data === undefined) {
        console.log(`Fetching [${storageKey}] from Sleeper`);
        const response = await fetch(endpoint);
        let data: T = await response.json();

        // Slim down for storage if we're looking at players
        if (storageKey === PLAYERS_KEY) {
            // let playerData: Map<string, Player> = data as Map<string, Player>;
            let playerData: Map<string, Player> = new Map(Object.entries<Player>(data as any));
            let filterPlayerData = filterPlayers(playerData);
            putItem(storageKey, Object.fromEntries(filterPlayerData));
            data = Object.fromEntries(filterPlayerData) as T;
        } else {
            putItem(storageKey, data);
        }

        console.log(`${storageKey} [sleeper]`, data);
        return data;
    } else {
        console.log(`${storageKey} available in local storage`);

        if (storageKey !== PLAYERS_KEY) {
            console.log(`${storageKey} [local storage]`, data);
        }
        return data;
    }
};

/**
 * Filters the players payload to only attributes used in this app.
 * @param players
 * @returns a subset of fields for each player
 */
const filterPlayers = (players: Map<string, Player>) => {
    let players_small: Map<string, Player> = new Map<string, Player>();
    // Array.from(players).map(key => (
    players.forEach((player,key) => (
        players_small.set(key, {
            player_id: player.player_id,
            position: player.position,
            last_name: player.last_name,
            first_name: player.first_name,
            full_name: player.full_name,
            fantasy_positions: player.fantasy_positions,
            height: player.height,
            weight: player.weight,
            age: player.age,
            team: player.team,
            status: player.status,
            years_exp: player.years_exp,
        })
    ));

    putItem(PLAYERS_KEY, players_small);
    return players_small;
};


export function getMatchupIdToMatchupsMap(matchups: Matchups[]) {
    const matchupIdToMatchups = new Map<string, Matchup[]>();
    matchups.forEach(week => {
        week.matchups.forEach(matchup => {
            let matchupId = `${week.week}-${matchup.matchup_id}`;

            let matchups = matchupIdToMatchups.get(matchupId);
            if (matchups === undefined) {
                matchups = [matchup];
                matchupIdToMatchups.set(matchupId, matchups);
            } else {
                matchups.push(matchup);
            }
        });
    });
    return matchupIdToMatchups;
}

export function getUserIdToUserMap(users: User[]) {
    const userIdUserMap = new Map<string, User>();
    users.forEach(user => userIdUserMap.set(user.user_id, user));
    return userIdUserMap;
}

export function getRosterIdToRosterMap(rosters: Roster[]) {
    const rosterIdRosterMap = new Map<number, Roster>();
    rosters.forEach(roster => rosterIdRosterMap.set(roster.roster_id, roster));
    return rosterIdRosterMap;
}

export function getRosterIdToDisplayNameMap(rosters: Roster[], userIdUserMap: Map<string, User>) {
    const rosterIdToDisplayNameMap = new Map<number, string>();

    rosters.forEach(roster => {
        let user = userIdUserMap.get(roster.owner_id);
        if (user === undefined) {
            throw new Error(`Owner id now found: ${roster.owner_id}`);
        }
        rosterIdToDisplayNameMap.set(roster.roster_id, user.display_name);
    });

    return rosterIdToDisplayNameMap;
}
