import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {Spotify} from "react-spotify-embed";
import {useTitle} from "../hooks/common";
import LeaguePointsForChart from "../components/LeaguePointsForChart";
import LeagueStandings from "../components/LeagueStandings";
import MatchupAwards from "../components/MatchupAwards";
import ScoreClub, {AwardSort} from "../components/ScoreClub";

export default function Home() {
    useTitle("Family Matters Fantasy Football League | FMFFL");

    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={12} className="text-center">
                    <h1 className="font-weight-light">Family Matters Fantasy Football League</h1>
                    <hr/>
                </Col>
            </Row>
            <LeagueStandings/>
            <MatchupAwards/>
            <ScoreClub title="Best Scores" sort={AwardSort.DESC} limit={5} />
            <ScoreClub title="Worst Scores" sort={AwardSort.ASC} limit={5} />

            <LeaguePointsForChart/>
            <Row>
                <Spotify link="https://open.spotify.com/show/3JADuYTzz0eJsprM19kMtK?si=041b0ec8dc34487b"/>
            </Row>
            <Row>
                <Col sm={12} >
                    <p>Over a decade, this tight-knit 12-team league blends the passion for football with cherished connections. From spirited draft days and touchdowns that write our history, to our vibrant league chat uniting veterans and newcomers, FMFFL stands for both competition and camaraderie.</p>

                    <p>Here, we've faced the gut-punch of losing top draft picks to retirement or injury, turning setbacks into strategic triumphs. We revel in water bets, soaking draft day participants to honor wagers and embrace the game's unpredictability. And the art of avoiding the toilet bowl adds an extra layer of excitement, showcasing skill and determination.</p>

                    <p>With playoffs approaching, our cheers grow louder and our bonds tighter, honoring touchdowns and meaningful moments. Don't miss our weekly podcast, where we unpack triumphs, challenges, and stories that make FMFFL more than a league – it's a tale woven by us all.</p>
                </Col>
            </Row>
        </Container>
    );
}
