import {useEffect} from "react";

/**
 * Sets the title of the current document
 * @param documentTitle
 */
export function useTitle(documentTitle: string) {
    useEffect(() => {
        document.title = documentTitle;
    });
}