import {Col, Container, Image, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    AVATAR_DEFAULT_URL_PREFIX, getLeague,
    getMatchups,
    getNflState,
    getRosters,
    getUsers,
    Matchup,
    User
} from "../lib/utils/sleeper";
import {useTitle} from "../hooks/common";

interface Team {
    rosterId: number,
    avatar: string,
    displayName: string,
    wins: number,
    losses: number,
    ties: number,
    winsAll: number,
    lossesAll: number,
    tiesAll: number,
    pointsFor: number,
    pointsAgainst: number,
}

function PowerRankingTeamTableRow({team}: { team: Team }) {
    return (
        <tr>
            <td><Image width={25} className={"player-image rounded"} src={team.avatar}/> {team.displayName}</td>
            <td>{team.winsAll}-{team.lossesAll}-{team.tiesAll}</td>
            <td>{team.wins}-{team.losses}-{team.ties}</td>
            <td>{team.pointsFor}</td>
        </tr>
    );
}

export default function PowerRankings() {
    useTitle("Power Rankings | FMFFL");

    const [teams, setTeams] = useState<Team[]>([]);

    const loadSchedule = async () => {
        // Load Sleeper Info
        let rosters = await getRosters();
        let users = await getUsers();
        let nflState = await getNflState();
        let league = await getLeague();

        let userIdUserMap = new Map<string, User>();
        users.forEach(user => userIdUserMap.set(user.user_id, user));
        console.log("userIdMap", userIdUserMap);

        let teamsById = new Map<string, Team>();
        rosters.forEach(roster => {
            let user = userIdUserMap.get(roster.owner_id);
            let teamName = (user === undefined) ? "unknown" : user.display_name;
            let avatar = "";
            if (user !== undefined) {
                avatar = (user.metadata.avatar !== undefined) ? avatar = user.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + user.avatar;
            }

            let team: Team = {
                rosterId: roster.roster_id,
                avatar: avatar,
                displayName: teamName,
                wins: roster.settings.wins,
                losses: roster.settings.losses,
                ties: roster.settings.ties,
                pointsFor: roster.settings.fpts + roster.settings.fpts_decimal * 0.01,
                pointsAgainst: roster.settings.fpts_against + roster.settings.fpts_against_decimal * 0.01,
                winsAll: 0,
                lossesAll: 0,
                tiesAll: 0,
            };

            teamsById.set(team.displayName, team);
        });

        let isCurrentSeason = nflState.season === league.season;
        let current_week = isCurrentSeason ? nflState.week : league.settings.playoff_week_start;
        let playoff_week = league.settings.playoff_week_start;

        for (let week = 1; week < Math.min(current_week, playoff_week); week++) {
            // Get current week matchups
            let matchups = await getMatchups(week);

            // Build lookup
            let rosterIdToMatchup = new Map<number, Matchup>();
            matchups.forEach(matchup => {
                rosterIdToMatchup.set(matchup.roster_id, matchup);
            });


            Array.from(teamsById.values()).forEach(team => {
                let myMatchup = rosterIdToMatchup.get(team.rosterId);
                let myPoints = (myMatchup === undefined) ? 0 : myMatchup.points;
                matchups.forEach(matchup => {
                    if (matchup.roster_id !== team.rosterId) {
                        let theirPoints = matchup.points;
                        if (myPoints > theirPoints) {
                            team.winsAll += 1;
                        } else if (myPoints < theirPoints) {
                            team.lossesAll += 1;
                        } else {
                            team.tiesAll += 1;
                        }
                    }
                });
            });
        }

        setTeams(Array.from(teamsById.values()).sort((t1,t2) => {
            // sort by wins
            if (t1.winsAll > t2.winsAll) {
                return -1;
            }
            if (t1.winsAll < t2.winsAll) {
                return 1;
            }

            // sort by points
            if (t1.pointsFor > t2.pointsFor) {
                return -1;
            }
            if (t1.pointsFor < t2.pointsFor) {
                return 1;
            }

            // tie
            return 0;
        }));
    };

    useEffect(() => {
        loadSchedule();
    }, []);

    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={12} className="text-center">
                    <h1 className="font-weight-light">Power Rankings</h1>
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover className="nowrap">
                    <thead>
                    <tr>
                        <th>Team</th>
                        <th>Power<br/>W-L-T</th>
                        <th>W-L-T</th>
                        <th>PF</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        teams.map(team => <PowerRankingTeamTableRow key={team.rosterId} team={team}/>)
                    }
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}
