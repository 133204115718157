import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import {useTitle} from "../hooks/common";
import {playoff_report_text} from "../data/playoff_report_utils";

export default function PlayoffReport() {
    useTitle("Playoff Report | FMFFL");

    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={12} className="text-center">
                    <h1 className="font-weight-light">Playoff Report</h1>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col sm={12} className="text-center">
                    <h2 className="font-weight-light">Summary</h2>
                </Col>
                {playoff_report_text.filter(line => line.startsWith("Team")).map(line => <p>{line.substring(6)}</p>)}
            </Row>
            <Row>
                <Col sm={12} className="text-center">
                    <h2 className="font-weight-light">Full Report</h2>
                </Col>
                {playoff_report_text.map(line => line.startsWith("Team") ? <p><b>{line}</b></p> : <p>{line}</p>)}
            </Row>
        </Container>
    );
}
