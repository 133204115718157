import React from "react";
import {CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis} from "recharts";
import {useTeamScores} from "../hooks/TeamScore";
import {Col, Row} from "react-bootstrap";

export default function LeaguePointsForChart() {
    const teamScores = useTeamScores();

    return (
        <Row hidden={teamScores.length === 0}>
            <Col sm={12} className="text-center">
                <h2>Season Scores</h2>
            </Col>
            <ResponsiveContainer width={"99%"} height={400}>
                <ScatterChart
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: -20,
                    }}
                >
                    <Legend layout={"vertical"} align={"center"} verticalAlign="bottom" fill={"black"}/>
                    <CartesianGrid />
                    <XAxis tickLine={false} type="number" dataKey="week" domain={[1, 'dataMax']}/>
                    <YAxis axisLine={false} tickLine={false} tickCount={10} type="number" dataKey="pointsFor" name="points" domain={[0, 225]} interval={0}/>
                    <Scatter name="Wins" data={teamScores.filter(t => t.isWin)} fill="#378805"/>
                    <Scatter name="Losses" data={teamScores.filter(t=>!t.isWin)} fill="#d10e04"/>
                </ScatterChart>
            </ResponsiveContainer>
        </Row>
    );
}