interface TimeStampedValue<T> {
    timestamp: number,
    value: T
}

/**
 * Stores and wraps data with the current timestamp.
 * @param key
 * @param value
 */
export const putItem = function  <T> (key: string, value: T) {
    console.log(`[${key}] - PUT`);

    let timestampedValue : TimeStampedValue<T> = {
        timestamp: new Date().getTime(),
        value: value
    };

    window.localStorage.setItem(key, JSON.stringify(timestampedValue));

    console.log(`[${key}] Localstorage total size ${localStorageSize()}kb`);
};

/**
 * Gets the value for a given key. Returns null if older than durationMs
 * @param key
 * @param durationMs
 */
export const getItem = function <T> (key: string, durationMs: number) : T | undefined {
    let json = window.localStorage.getItem(key);
    if(json === null) {
        console.log(`[${key}] - GET MISS`);
        return undefined;
    }

    try {
        let timestampedValue : TimeStampedValue<T> = JSON.parse(json);
        if(timestampedValue.value === undefined) {
            console.log(`[${key}] - Corrupt value`);
            return undefined;
        }

        let now = new Date().getTime();
        if (now - timestampedValue.timestamp > durationMs) {
            console.log(`[${key}] Cache expired, older than ${durationMs}`);
            return undefined;
        } else {
            console.log(`${key} - GET HIT`);
        }

        return timestampedValue.value;
    } catch (error) {
        console.warn(`${key} Failed to parse value`, error);
        return undefined;
    }
};

export const localStorageSize = function () {
    let _lsTotal = 0,_xLen, _x;
    for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) continue;
        _xLen = (localStorage[_x].length + _x.length) * 2;
        _lsTotal += _xLen;
    }
    return  (_lsTotal / 1024).toFixed(2);
};
