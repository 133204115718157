import {useEffect, useState} from "react";
import {
    getAllMatchups, getLeague,
    getNflState,
    getRosters,
    getUsers,
    League,
    Matchup,
    NflState,
    Roster,
    User
} from "../lib/utils/sleeper";

export const PositionsDisplayOrder = ["QB", "RB", "WR", "TE", "FLEX", "IDP_FLEX", "DL", "LB", "DB", "BN"];

export interface Matchups {
    week: number,
    matchups: Matchup[]
}

export interface HeadToHeadMatchup {
    week: number,
    matchupId: string
    homeTeam: Matchup,
    awayTeam: Matchup
}

export function useLeague() {
    const [league, setLeague] = useState<League>();
    useEffect(() => {
        getLeague()
            .then(setLeague)
            .catch(console.error);
    }, []);

    return league;
}

export function useNflState() {
    const [nflState, setNflState] = useState<NflState>();
    useEffect(() => {
        getNflState()
            .then(setNflState)
            .catch(console.error);
    }, []);

    return nflState;
}

export function useRosters() {
    const [rosters, setRosters] = useState<Roster[]>([]);
    useEffect(() => {
        getRosters()
            .then(setRosters)
            .catch(console.error);
    }, []);

    return rosters;
}

export function useUsers() {
    const [users, setUsers] = useState<User[]>([]);
    useEffect(() => {
        getUsers()
            .then(setUsers)
            .catch(console.error);
    }, []);

    return users;
}

export function useMatchups() {
    const [matchups, setMatchups] = useState<Matchups[]>([]);
    const league = useLeague();
    useEffect(() => {
        if(league !== undefined) {
            let playoffWeek = league.settings.playoff_week_start;
            getAllMatchups(playoffWeek)
                .then(setMatchups)
                .catch(console.error);
        }
    }, [league]);

    return matchups;
}
export function useHeadToHeadMatchups() {
    const allMatchups = useMatchups();
    const [headToHeadMatchups, setHeadToHeadMatchups] = useState<HeadToHeadMatchup[]>([]);

    useEffect(() => {
        const headToHeadMatchups: HeadToHeadMatchup[] = [];

        allMatchups.forEach(weeklyMatchupList => {
            const currentWeek = weeklyMatchupList.week;
            const matchupIdToMatchup = new Map<string, Matchup>();

            weeklyMatchupList.matchups.forEach(matchup => {
                const currentMatchupId = matchup.matchup_id;
                const opponentMatchup = matchupIdToMatchup.get(currentMatchupId);

                if (opponentMatchup === undefined) {
                    matchupIdToMatchup.set(currentMatchupId, matchup);
                } else {
                    headToHeadMatchups.push({
                        week: currentWeek,
                        matchupId: currentMatchupId,
                        homeTeam: opponentMatchup,
                        awayTeam: matchup
                    });
                }
            });
        });

        setHeadToHeadMatchups(headToHeadMatchups);
    }, [allMatchups]);

    return headToHeadMatchups;
}