import React, {useEffect, useState} from 'react';
import {Col, Row, Table} from "react-bootstrap";
import {useHeadToHeadMatchups, useLeague, useNflState, useRosters, useUsers} from "../hooks/sleeper";
import {
    AVATAR_DEFAULT_URL_PREFIX,
    getRosterIdToDisplayNameMap,
    getRosterIdToRosterMap,
    getUserIdToUserMap
} from "../lib/utils/sleeper";
import TeamAvatar from "./TeamAvatar";

export interface TeamMatchupAward {
    week: number,
    displayName: string,
    avatar: string,
    pointsFor: number,
}

export enum AwardSort {
    ASC, DESC
}

export type AwardFilter = (award: TeamMatchupAward) => boolean;

function ScoreClub({title, sort, limit}: {title: string, sort:AwardSort, limit: number}) {
    const matchups = useHeadToHeadMatchups();
    const rosters = useRosters();
    const users = useUsers();
    const nflState = useNflState();
    const league = useLeague();
    const [currentWeek, setCurrentWeek] = useState(0);


    const [teamMatchupAwards, setTeamMatchupAwards] = useState<TeamMatchupAward[]>([]);

    useEffect(() => {
        if (matchups.length === 0 || rosters.length === 0 || users.length === 0 || nflState === undefined || league === undefined) {
            return;
        }

        const userIdMap = getUserIdToUserMap(users);
        const rosterIdToDisplayNameMap = getRosterIdToDisplayNameMap(rosters, userIdMap);
        const userIdUserMap = getUserIdToUserMap(users);
        const rosterIdToRostersMap = getRosterIdToRosterMap(rosters);

        // Determine Current Week
        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;

        const awards: TeamMatchupAward[] = matchups.filter(m => m.week < current_week).flatMap(m=> {
            const homeName = rosterIdToDisplayNameMap.get(m.homeTeam.roster_id);
            const awayName = rosterIdToDisplayNameMap.get(m.awayTeam.roster_id);
            if (homeName === undefined || awayName === undefined) {
                throw new Error(`Unable to find display names for roster ids`);
            }

            const homeRoster = rosterIdToRostersMap.get(m.homeTeam.roster_id);
            const awayRoster = rosterIdToRostersMap.get(m.awayTeam.roster_id);
            if (homeRoster === undefined || awayRoster === undefined) {
                throw new Error(`Unable to find rosters for roster ids`);
            }

            const homeUser = userIdUserMap.get(homeRoster.owner_id);
            const awayUser = userIdUserMap.get(awayRoster.owner_id);
            if (homeUser === undefined || awayUser === undefined) {
                throw new Error(`Unable to find users from owner ids`);
            }

            let homeAvatar = (homeUser.metadata.avatar !== undefined) ? homeUser.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + homeUser.avatar;
            let awayAvatar = (awayUser.metadata.avatar !== undefined) ? awayUser.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + awayUser.avatar;

            return [
                {week: m.week, displayName: homeName, avatar: homeAvatar, pointsFor: m.homeTeam.points},
                {week: m.week, displayName: awayName, avatar: awayAvatar, pointsFor: m.awayTeam.points},
            ];
        });

        if(sort === AwardSort.ASC) {
            awards.sort((a,b) => a.pointsFor - b.pointsFor);
        } else {
            awards.sort((a,b) => b.pointsFor - a.pointsFor);
        }

        setCurrentWeek(current_week);
        setTeamMatchupAwards(awards.slice(0, limit));
    }, [matchups, rosters, users, limit, sort, nflState, league]);

    return (
        <Row hidden={currentWeek <= 1}>
            <Col sm={12} className="text-center">
                <h2>{title}</h2>
            </Col>
            <Col xl={12}>
                <Table striped bordered hover className="nowrap">
                    <thead>
                    <tr>
                        <th>Team</th>
                        <th>PF</th>
                        <th>Week</th>
                    </tr>
                    </thead>
                    <tbody>

                    {teamMatchupAwards.map(m =>
                            <tr key={m.displayName + "-" + m.week}>
                                <td><TeamAvatar displayName={m.displayName} avatar={m.avatar} avatarLeft={true} highlight={false}/></td>
                                <td>{m.pointsFor}</td>
                                <td>{m.week}</td>
                            </tr>
            )}
                    </tbody>
                </Table>
            </Col>

        </Row>
    );
}

export default ScoreClub;