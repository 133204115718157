import React, {useEffect, useState} from 'react';
import {Row, Table} from "react-bootstrap";
import {PlayerPositionScore, usePlayerPositionScores} from "../hooks/PlayerPositionScores";
import {PositionsDisplayOrder, useLeague, useNflState} from "../hooks/sleeper";


export interface PositionAverage {
    position: string,
    sum: number,
    count: number,
    className: string
}

/**
 * Reducer for PlayerPositionScore reduced to averages by Position
 */
function toPositionAverageMap() {
    return (positionAverageMap: Map<string, PositionAverage>, pps: PlayerPositionScore) => {
        let positionAverage = positionAverageMap.get(pps.playedAsPosition);
        if (positionAverage === undefined) {
            positionAverage = {position: pps.playedAsPosition, sum: 0, count: 0, className: ""};
            positionAverageMap.set(pps.playedAsPosition, positionAverage);
        }

        positionAverage.sum += pps.points;
        positionAverage.count += 1;

        return positionAverageMap;
    };
}

function byPosition() {
    return (a: PositionAverage, b: PositionAverage) => {
        if (a.position === b.position) {
            return 0;
        }

        let aPos = PositionsDisplayOrder.indexOf(a.position);
        let bPos = PositionsDisplayOrder.indexOf(b.position);
        return aPos - bPos;
    };
}

function TeamPositionTable({hidden, displayName}: { hidden: boolean, displayName: string }) {
    const playerPositionScores = usePlayerPositionScores();

    const [teamPositionAverage, setTeamPositionAverage] = useState<PositionAverage[]>([]);
    const [divisionPositionAverage, setDivisionPositionAverage] = useState<PositionAverage[]>([]);
    const [leaguePositionAverage, setLeaguePositionAverage] = useState<PositionAverage[]>([]);

    const nflState = useNflState();
    const league = useLeague();

    useEffect(() => {
        if (playerPositionScores.length === 0 || nflState === undefined || league === undefined) {
            return;
        }

        // Determine Current Week
        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;

        let leaguePositionAveragesMap = playerPositionScores
            .filter(pps => pps.starter && pps.week < current_week)
            .reduce(toPositionAverageMap(), new Map<string, PositionAverage>());

        let leaguePositionAverages = Array.from(leaguePositionAveragesMap.values());
        leaguePositionAverages.sort(byPosition());
        setLeaguePositionAverage(leaguePositionAverages);
    }, [playerPositionScores, league, nflState]);

    useEffect(() => {
        if (leaguePositionAverage.length === 0 || playerPositionScores.length === 0 || displayName === "" || nflState === undefined || league === undefined) {
            return;
        }

        // Determine Current Week
        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;

        // Compute Division Averages
        let division = playerPositionScores
            .filter(pps => pps.week < current_week)
            .filter(pps => displayName === pps.teamDisplayName)
            .filter(pps => pps.starter)
            .map(pps => pps.division)
            .reduce((previousValue, currentValue) => currentValue, -1);

        let divisionPositionAverageMap = playerPositionScores
            .filter(pps => pps.week < current_week)
            .filter(pps => pps.starter)
            .filter(pps => pps.division === division)
            .reduce(toPositionAverageMap(), new Map<string, PositionAverage>());

        // Compute Team Averages
        let divisionPositionAverages = Array.from(divisionPositionAverageMap.values());
        divisionPositionAverages.sort(byPosition());
        console.log("Division Averages: ", divisionPositionAverages);
        setDivisionPositionAverage(divisionPositionAverages);

        let teamPositionAverageMap = playerPositionScores
            .filter(pps => pps.week < current_week)
            .filter(pps => displayName === pps.teamDisplayName)
            .filter(pps => pps.starter)
            .reduce(toPositionAverageMap(), new Map<string, PositionAverage>());

        let teamPositionAverages = Array.from(teamPositionAverageMap.values());
        teamPositionAverages.sort(byPosition());
        console.log("Team Averages: ", teamPositionAverages);

        teamPositionAverages.forEach(item => {
           let leagueAveragePos = leaguePositionAverage.find(x => x.position === item.position);
           let divisionAveragePos = divisionPositionAverages.find(x => x.position === item.position);

           if (leagueAveragePos === undefined) {
               throw new Error("Unable to find league positions!");
           }

           if(divisionAveragePos === undefined) {
               throw new Error("Unable to find division positions!");
           }

           let posAvg = item.sum / item.count;
           let leagueAvg = leagueAveragePos.sum / leagueAveragePos.count;
           let divAvg = divisionAveragePos.sum / divisionAveragePos.count;

           if (posAvg < leagueAvg && posAvg < divAvg) {
               item.className = 'text-danger';
           } else if (posAvg > leagueAvg && posAvg > divAvg) {
               item.className = 'text-success';
           }
        });

        setTeamPositionAverage(teamPositionAverages);
    }, [displayName, playerPositionScores, leaguePositionAverage, league, nflState]);

    return (
        <div className="" hidden={teamPositionAverage.length === 0}>
            <Row className="text-center">
                <h5>Position Average Score</h5>
            </Row>
            <Table size="sm" className="nowrap reallySmallTable" hidden={hidden}>
                <thead>
                <tr className="text-end">
                    <th></th>
                    {
                        teamPositionAverage.map(x => <th key={x.position}>{x.position}</th>)
                    }
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th className="text-end">Team</th>
                    {
                        teamPositionAverage.map(item =>
                            <td key={'team-' + item.position} className={"text-end " + item.className}>{(item.sum / item.count).toFixed(2)}</td>
                        )
                    }
                </tr>
                <tr>
                    <th className="text-end">Division</th>
                    {
                        divisionPositionAverage.map(item =>
                            <td key={'dp-' + item.position} className={"text-end " + item.className}>{(item.sum / item.count).toFixed(2)}</td>
                        )
                    }

                </tr>
                <tr>
                    <th className="text-end">League</th>
                    {
                        leaguePositionAverage.map(item =>
                            <td key={'lp-' + item.position} className={"text-end " + item.className}>{(item.sum / item.count).toFixed(2)}</td>
                        )
                    }
                </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default TeamPositionTable;