import {Col, Container, Image, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    AVATAR_DEFAULT_URL_PREFIX, getLeague,
    getMatchups,
    getNflState,
    getRosters,
    getUsers
} from "../lib/utils/sleeper";
import {useTitle} from "../hooks/common";

//TODO: Move this to common space
export interface TeamRecord {
    avatar: string,
    displayName: string,
    rosterId: number,
    ownerId: string,
    wins: number,
    loss: number,
    ties: number,
    points: number[],
    theoryPoints: number[],
    avgScore: number,
    maxScore: number,
    minScore: number,
    theoryWins: number,
    theoryLoss: number,
    theoryTotalPoints: number,
}
function TeamRecordRow({teamRecord} : {teamRecord: TeamRecord}) {
    return (
        <tr>
            <th className="teamDisplay"><Image width={25} className={"player-image rounded"} src={teamRecord.avatar}/> {teamRecord.displayName}</th>
            <td>{teamRecord.theoryTotalPoints}</td>
            <td>{teamRecord.theoryWins}-{teamRecord.theoryLoss}</td>
            <td>{teamRecord.wins}-{teamRecord.loss}-{teamRecord.ties}</td>
            <td>{teamRecord.avgScore}</td>
            <td>{teamRecord.maxScore}</td>
            <td>{teamRecord.minScore}</td>
        </tr>
    );
}

export default function BigBoard() {
    useTitle("David's Big Board | FMFFL");

    const columnHeaders = ["Team",  "BB Points", "BB W-L", "W-L-T", "Avg Score", "High Score", "Low Score"];

    useEffect(() => {
        loadSchedule();
    }, []);

    const [teamRecords, setTeamRecords] = useState<TeamRecord[]>([]);

    const loadSchedule = async () => {
        // Load Sleeper Info
        let users = await getUsers();
        let rosters = await getRosters();
        let nflState = await getNflState();
        let league = await getLeague();

        console.log("users", users);
        console.log("nfl state", nflState);
        console.log("rosters", rosters);
        console.log("league", league);

        //TODO: Handle first week and no week!
        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;
        console.log(`Current week: ${current_week}`);

        let userIdUserMap = new Map();
        users.forEach(user => userIdUserMap.set(user.user_id, user));
        console.log("userIdMap", userIdUserMap);

        let teamRecords = new Map<number, TeamRecord>();
        rosters.forEach(roster => {
            let user = userIdUserMap.get(roster.owner_id);
            let avgScore = (roster.settings.fpts + roster.settings.fpts_decimal * .01) / (current_week - 1);
            let avatar = "";
            if (user !== undefined) {
                avatar = (user.metadata.avatar !== undefined) ? avatar = user.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + user.avatar;
            }

            let teamRecord : TeamRecord = {
                avatar: avatar,
                displayName: user.display_name,
                rosterId: roster.roster_id,
                ownerId: roster.owner_id,
                wins: roster.settings.wins, // score by week
                loss: roster.settings.losses, // score by week
                ties: roster.settings.ties, // score by week
                points: [],
                theoryPoints: [],
                avgScore: parseFloat(avgScore.toFixed(2)),
                maxScore: 0,
                minScore: 0,
                theoryWins: 0,
                theoryLoss: 0,
                theoryTotalPoints: 0,
            };
            teamRecords.set(roster.roster_id, teamRecord);
        });

        // Append scores
        for (let week_number = 1; week_number < Math.min(current_week, playoff_week); week_number++) {
            let weekly_scores: number[] = [];
            let scoreToRosterIds: Map<number, number[]> = new Map<number, number[]>();

            let matchups = await getMatchups(week_number);

            console.log('matchups', matchups);
            matchups.forEach(matchup => {
                let rosterId = matchup.roster_id;
                let points = matchup.points;

                let teamRecord = teamRecords.get(rosterId);
                if(teamRecord !== undefined) {
                    teamRecord.points.push(points);
                }

                weekly_scores.push(points);
                let rosterIds = scoreToRosterIds.get(points);
                if (rosterIds === undefined) {
                    rosterIds = [];
                    scoreToRosterIds.set(points, rosterIds);
                }
                rosterIds.push(rosterId);
            });

            // Sort descending
            weekly_scores.sort(function (a, b) {
                return b - a;
            });

            // Attribute theory scores for week
            let score = 12;
            weekly_scores.forEach(weekly_score => {
                let count = 0;
                let rosterIds = scoreToRosterIds.get(weekly_score);
                if(rosterIds !== undefined) {
                    rosterIds.forEach(rosterId => {
                        let teamRecord = teamRecords.get(rosterId);
                        if(teamRecord !== undefined) {
                            teamRecord.theoryPoints.push(score);
                        }
                        count += 1;
                    });
                }
                score -= count;
            });
        }

        // Compute Min/Max
        teamRecords.forEach(teamRecord => {
            teamRecord.minScore = Math.min(...teamRecord.points);
            teamRecord.maxScore = Math.max(...teamRecord.points);
            teamRecord.theoryTotalPoints = teamRecord.theoryPoints.reduce((partialSum: number, a: number) => partialSum + a, 0);
            teamRecord.theoryWins = teamRecord.theoryPoints.map((points: number) => points > 6 ? 1 : 0).reduce((partialSum: number, a: number) => partialSum + a, 0);
            teamRecord.theoryLoss = teamRecord.theoryPoints.map((points: number) => points <= 6 ? 1 : 0).reduce((partialSum: number, a: number) => partialSum + a, 0);
        });

        console.log("TeamRecords", teamRecords);

        let sortedRecords = Array.from(teamRecords.values()).sort((t1,t2) => {
            // sort by theoryTotalPoints
            if (t1.theoryTotalPoints > t2.theoryTotalPoints) {
                return -1;
            }
            if (t1.theoryTotalPoints < t2.theoryTotalPoints) {
                return 1;
            }

            // sort by theoryWins
            if (t1.theoryWins > t2.theoryWins) {
                return -1;
            }
            if (t1.theoryWins < t2.theoryWins) {
                return 1;
            }

            // sort by theoryWins
            if (t1.wins > t2.wins) {
                return -1;
            }
            if (t1.wins < t2.wins) {
                return 1;
            }

            // sort by theoryWins
            if (t1.points > t2.points) {
                return -1;
            }
            if (t1.points < t2.points) {
                return 1;
            }

            // tie
            return 0;
        });

        setTeamRecords(sortedRecords);
    };


    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={12} className="text-center">
                    <h1 className="font-weight-light">David's Big Board</h1>
                </Col>
            </Row>
            <Table striped bordered hover responsive className="nowrap">
                <thead>
                <tr>
                    {
                        columnHeaders.map(columnHeader => <th key={columnHeader}>{columnHeader}</th>)
                    }
                </tr>
                </thead>
                <tbody>
                {
                    teamRecords.map(teamRecord => <TeamRecordRow key={teamRecord.rosterId} teamRecord={teamRecord}/>)
                }
                </tbody>
            </Table>
        </Container>
    );
}
