import {Col, Container, Image, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {AVATAR_DEFAULT_URL_PREFIX, getUserIdToUserMap} from "../lib/utils/sleeper";
import {useTitle} from "../hooks/common";
import {useLeague, useMatchups, useNflState, useRosters, useUsers} from "../hooks/sleeper";

interface TeamRecord {
    avatar: string,
    displayName: string,
    rosterId: number,
    ownerId: string,
    points: number[],
    opponentPoints: number[],
    opponentRosterIds: number[],
}

interface RecordRow {
    avatar: string,
    displayName: string,
    rosterId: number,
    wins: number[],
    losses: number[],
    ties: number[],
    records: string[],
    leagueWins: number,
    leagueLosses: number,
    leagueTies: number,
    leagueWinPercentage: number,
    winningRecordCount: number,
    losingRecordCount: number,
    atLeastSevenWins: number,
    atLeastSevenLoss: number
}

function RecordTr({record, rowIndex}: { record: RecordRow, rowIndex: number }) {
    return (
        <tr>
            <TeamHeader record={record}/>
            {record.records.map((wlt, wltIndex) => rowIndex === wltIndex ? <th>{wlt}</th> : <td>{wlt}</td>)}
        </tr>
    );
}
function TeamHeader({record}: { record: RecordRow }) {
    return (
        <th key={record.rosterId}><Image width={25} className={"player-image rounded"}
                                         src={record.avatar}/> {record.displayName}</th>
    );
}

export default function Stu() {
    useTitle("Stuart's Schedule | FMFFL");

    const users = useUsers();
    const rosters = useRosters();
    const nflState = useNflState();
    const league = useLeague();
    const allMatchups = useMatchups();

    const [records, setRecords] = useState<RecordRow[]>([]);

    useEffect(() => {
        if (league === undefined || nflState === undefined || rosters.length === 0 || users.length === 0 || allMatchups.length === 0) {
            return;
        }

        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;
        let userIdUserMap = getUserIdToUserMap(users);

        let rosterIdToTeamRecordMap = new Map<number, TeamRecord>();
        rosters.forEach(roster => {
            let user = userIdUserMap.get(roster.owner_id);
            if (user === undefined) {
                throw new Error(`Unable to find user by owner id ${roster.owner_id}`);
            }

            let avatar = (user.metadata.avatar !== undefined) ? user.metadata.avatar : AVATAR_DEFAULT_URL_PREFIX + user.avatar;

            let teamRecord: TeamRecord = {
                avatar: avatar,
                displayName: user.display_name,
                rosterId: roster.roster_id,
                ownerId: roster.owner_id,
                points: [], // score by week
                opponentPoints: [], // score by week of opponent
                opponentRosterIds: []
            };
            rosterIdToTeamRecordMap.set(roster.roster_id, teamRecord);
        });

        allMatchups.filter(m => m.week < Math.min(current_week, playoff_week)).forEach(weekMatchups => {
            let matchups = weekMatchups.matchups;
            let matchupCache = new Map();

            matchups.forEach(matchup => {
                let matchupId = matchup.matchup_id;
                let rosterId = matchup.roster_id;
                let points = matchup.points;
                let teamRecord = rosterIdToTeamRecordMap.get(rosterId);
                if (teamRecord !== undefined) {
                    teamRecord.points.push(points);

                    let otherMatchup = matchupCache.get(matchupId);
                    if (otherMatchup === undefined) {
                        // not yet processed other matchup..
                        matchupCache.set(matchupId, matchup);
                    } else {
                        // processed both matchups, time to append opponent matchup to records
                        teamRecord.opponentPoints.push(otherMatchup.points);
                        teamRecord.opponentRosterIds.push(otherMatchup.roster_id);

                        let otherTeamRecord = rosterIdToTeamRecordMap.get(otherMatchup.roster_id);
                        if (otherTeamRecord !== undefined) {
                            otherTeamRecord.opponentPoints.push(points);
                            otherTeamRecord.opponentRosterIds.push(rosterId);
                        }
                    }
                }
            });
        });

        let allRecords = Array.from(rosterIdToTeamRecordMap.values());
        let records: RecordRow[] = [];
        allRecords.forEach(teamRecord => {
            let recordRow: RecordRow = {
                avatar: teamRecord.avatar,
                displayName: teamRecord.displayName,
                rosterId: teamRecord.rosterId,
                wins: [],
                losses: [],
                ties: [],
                records: [],
                leagueWins: 0,
                leagueLosses: 0,
                leagueTies: 0,
                leagueWinPercentage: 0,
                winningRecordCount: 0,
                losingRecordCount: 0,
                atLeastSevenWins: 0,
                atLeastSevenLoss: 0
            };

            const myRosterId = teamRecord.rosterId;

            // Calculate my record against all Schedules
            allRecords.forEach(opponentSchedule => {
                // Fresh record
                let wins = 0;
                let loss = 0;
                let tie = 0;

                // Compare Schedule
                teamRecord.points.forEach((myScore: number, week: number) => {
                    let theirOpponentScore = opponentSchedule.opponentPoints[week];
                    if (myRosterId === opponentSchedule.opponentRosterIds[week]) {
                        // I'm the opponent so use their actual score that week
                        theirOpponentScore = opponentSchedule.points[week];
                    }

                    if (myScore > theirOpponentScore) {
                        wins += 1;
                    } else if (myScore < theirOpponentScore) {
                        loss += 1;
                    } else {
                        tie += 1;
                    }
                });

                // Create Record
                recordRow.wins.push(wins);
                recordRow.losses.push(loss);
                recordRow.ties.push(tie);
                recordRow.records.push(`${wins}-${loss}-${tie}`);
            });

            records.push(recordRow);
        });

        // Compute League Record for Schedule
        records.forEach((leagueRecord, team) => {
            records.forEach(record => {
                const wins = record.wins[team];
                const losses = record.losses[team];
                const ties = record.ties[team];

                leagueRecord.leagueWins += wins;
                leagueRecord.leagueLosses += losses;
                leagueRecord.leagueTies += ties;

                const recordPercent = wins / (wins + losses + ties);
                if (recordPercent > 0.5) {
                    leagueRecord.winningRecordCount += 1;
                } else if (recordPercent < 0.5) {
                    leagueRecord.losingRecordCount += 1;
                }

                if (wins >= 8) {
                    leagueRecord.atLeastSevenWins += 1;
                }
                if (losses >= 8) {
                    leagueRecord.atLeastSevenLoss += 1;
                }
            });

            leagueRecord.leagueWinPercentage = leagueRecord.leagueWins / (leagueRecord.leagueWins + leagueRecord.leagueLosses + leagueRecord.leagueTies) * 100.0;
        });

        // // Sort records by leagueWinPercentage
        // records.sort((a,b) => {
        //     return b.leagueWinPercentage - a.leagueWinPercentage;
        // });

        setRecords(records);
    }, [league, nflState, users, rosters, allMatchups]);

    return (
        <Container className="px-4 my-5 pt-3">
            <Row>
                <Col sm={12} className="text-center">
                    <h1 className="font-weight-light">Stuart's Schedule</h1>
                    <h5 className="font-weight-light">"If I had their schedule, I would have won it all..."</h5>
                </Col>
            </Row>
            <Row hidden={records.length === 0} >
                <Table striped bordered hover responsive className="nowrap">
                    <thead>
                    <tr>
                        <th></th>
                        {records.map(record => <TeamHeader record={record}/>)}
                    </tr>
                    </thead>
                    <tbody>
                    {records.map((record, rowIndex) => <RecordTr record={record} rowIndex={rowIndex}/>)}
                    <tr><th>League Record</th>{records.map((record, rowIndex) => <th>{record.leagueWins}-{record.leagueLosses}-{record.leagueTies}</th>)}</tr>
                    <tr><th>League Win %</th>{records.map((record, rowIndex) => <th>{record.leagueWinPercentage.toFixed(2)}%</th>)}</tr>
                    <tr><th>Winning Records</th>{records.map((record, rowIndex) => <th>{record.winningRecordCount}</th>)}</tr>
                    <tr><th>Losing Records</th>{records.map((record, rowIndex) => <th>{record.losingRecordCount}</th>)}</tr>
                    <tr><th>High Wins (8+)</th>{records.map((record, rowIndex) => <th>{record.atLeastSevenWins}</th>)}</tr>
                    <tr><th>High Loss (8+)</th>{records.map((record, rowIndex) => <th>{record.atLeastSevenLoss}</th>)}</tr>
                    </tbody>
                </Table>
            </Row>
        </Container>
    );
}
