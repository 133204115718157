import {getAllMatchups, getLeague, getMatchupIdToMatchupsMap, getNflState} from "../lib/utils/sleeper";
import {useEffect, useState} from "react";

export interface TeamScore {
    week: number,
    teamDisplayName: string,
    pointsFor: number,
    isWin: boolean,
    isTie: boolean
}

export function useTeamScores() {
    async function getAllTeamsScores() {

        let teamsScores: TeamScore[] = [];
        let league = await getLeague();
        let nflState = await getNflState();

        let isCurrentSeason = nflState.season === league.season;
        let playoff_week = league.settings.playoff_week_start;
        let current_week = isCurrentSeason ? nflState.week : playoff_week;

        let allMatchups = await getAllMatchups(playoff_week);

        let matchupIdToMatchup = getMatchupIdToMatchupsMap(allMatchups.filter(m => m.week < Math.min(current_week, playoff_week)));

        Array.from(matchupIdToMatchup.keys()).forEach(key => {
            let [week] = key.split("-");
            let matchups = matchupIdToMatchup.get(key);
            if(matchups === undefined) {
                throw new Error(`Unable to find matchup with key: ${key}`);
            }

            let homeTeam = matchups[0];
            let awayTeam = matchups[1];

            let homeTeamScore: TeamScore = {
                week: parseInt(week),
                pointsFor: homeTeam.points,
                teamDisplayName: homeTeam.roster_id + "",
                isWin: homeTeam.points > awayTeam.points,
                isTie: homeTeam.points === awayTeam.points
            };

            let awayTeamScore: TeamScore = {
                week: parseInt(week),
                pointsFor: awayTeam.points,
                teamDisplayName: awayTeam.roster_id + "",
                isWin: awayTeam.points > homeTeam.points,
                isTie: awayTeam.points === homeTeam.points
            };

            teamsScores.push(homeTeamScore, awayTeamScore);
        });

        console.log("Team Scores: ", teamsScores);

        return teamsScores;
    }

    const [teamScores, setTeamScores] = useState<TeamScore[]>([]);
    useEffect(() => {
        getAllTeamsScores().then(setTeamScores).catch(console.error);
    }, []);

    return teamScores;
}