import React from 'react';
import {Image} from "react-bootstrap";

interface TeamProperties {
    displayName: string,
    avatar: string,
    avatarLeft: boolean,
    highlight: boolean
}

function TeamAvatar({displayName, avatar, avatarLeft, highlight}: TeamProperties) {
    return (
        <span className={highlight ? "fw-bold" : ""}>
            <Image hidden={!avatarLeft} width={25} className={"player-image rounded"} src={avatar}/> {displayName} <Image hidden={avatarLeft} width={25} className={"player-image rounded"} src={avatar}/>
        </span>
    );
}

export default TeamAvatar;